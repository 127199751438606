<template>
    <div>
        <list-picker
                label="Font Family"
                v-model="selectedValue"
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                :property-name="propertyName"
                :options="options"
        />
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import axios from 'axios'
import modificationDao from "../../daos/ModificationDao";
import _ from 'lodash'
import StyleSetter from "../../mixin/StyleSetter";
import Chrome from 'vue-color/src/components/Chrome.vue';
import tinycolor from 'tinycolor2';
import ColorPicker from "./ColorPicker";
import TextPicker from "./TextPicker";
import ImagePicker from "./ImagePicker";
import ListPicker from "./ListPicker";
import loadedFontFinder from "../../services/LoadedFontFinder";

export default {
    name: 'FontFamilySetter',
    components: {ListPicker, TextPicker},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'font-family',
            options: []
        }
    },

    methods: {
        async loadFonts() {
            this.options = await loadedFontFinder.findFonts();
        }
    },

    async mounted() {
      this.loadFonts();
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
