<template>
    <div>
        Margin Right
        <setter-layout>
            <slider-units-picker
                    @reset="onReset"
                    @undo="onUndo"
                    @redo="onRedo"
                    :max="500"
                    :min="0"
                    v-model="selectedValue"></slider-units-picker>
        </setter-layout>

    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";
import SetterLayout from "../SetterLayout";

export default {
    name: 'MarginRightSetter',
    components: {SetterLayout, SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
          propertyName: 'margin-right'
        }
    },

    methods: {

    },

    async mounted() {
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
