<template>
    <setter-layout>
        <list-picker
                label="Transform Function"
                v-model="selectedValue"
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                :property-name="propertyName"
                :options="options"
        />
    </setter-layout>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import ListPicker from "../ListPicker";
import SetterLayout from "../SetterLayout";
import transformResolver from "../../../services/propertyresolver/TransformResolver";

export default {
    name: 'TransformFunctionSetter',
    components: {SetterLayout, ListPicker},
    mixins: [StyleSetter],
    data(){
        return {
            itemId: 'transform-function',
            resolver: transformResolver,
            options: []
        }
    },

    methods: {
    },

    async mounted() {
      this.options = [
          'skew', 'rotate'
      ]
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
