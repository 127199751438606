<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Display</h2>
    </div>
    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3">
      <display-setter :el="el" :group-id="groupId"></display-setter>
      <box-sizing-setter :el="el" :group-id="groupId"></box-sizing-setter>
    </div>
  </div>
</template>
<script>

import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
import DisplaySetter from "../styles/display/DisplaySetter";
import BoxSizingSetter from "../styles/display/BoxSizingSetter";

export default {
  name: 'DisplayCategory',
  components: {
    BoxSizingSetter,
    DisplaySetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },

  mounted(){
  }
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
