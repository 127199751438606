import InteractionType from "./InteractionType";

class TaskCssFormatter{
    getForTaskIds(ids){
        let styles = {};
        interactionRecorder.visitActiveGroupProperties((t) => t.uuid,
            ids,
            InteractionType.STYLE,
            function(selector, propertyName, propertyValue){
                styles[selector] = styles[selector] || "";
                styles[selector] += "    " + propertyName + " : " + propertyValue + ";\n";
            });

        for(let selector of Object.keys(styles)){
            styles[selector] = " {\n" + styles[selector] + "}\n\n";
        }

        let final = "";
        for(let selector of Object.keys(styles)){
            final += selector + " " + styles[selector];
        }

        return final || "[ no active styles ]";
    }
}

let taskCssFormatter = new TaskCssFormatter();
export default taskCssFormatter;

