<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Background</h2>
    </div>
    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3">
      <background-color-setter :el="el" :group-id="groupId"></background-color-setter>
      <background-image-setter :el="el" :group-id="groupId"></background-image-setter>
    </div>
  </div>
</template>

<script>

import vClickOutside from 'v-click-outside'
import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";

export default {
  name: 'BackgroundCategory',
  components: {
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },

  mounted(){
  }
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
