import SingleCssValue from "../../models/SingleCssValue";
import store from "../../store/Store";
import Resolver from "./Resolver";
import cssParamParser from "../cssParamParser";

class TransformResolver extends Resolver{

    name = 'transform';
    propertyName = 'transform';

    _doResolve(selector, groupId, itemName, value){

        let filterFunction = this._getItemValueWithoutSpace(selector, groupId, 'transform-function', 'skew');

        let propertyValue;
        if(filterFunction === 'skew'){
            propertyValue =
                'skew(' + this._getItemValueWithoutSpace(selector, groupId, 'skewX', '0deg') + ','
                + this._getItemValueWithoutSpace(selector, groupId, 'skewY', '0deg') + ')';
        }

        if(filterFunction === 'rotate'){
            propertyValue =
                'rotateX('   + this._getItemValueWithoutSpace(selector, groupId, 'rotateX', '0deg') + ') ' +
                'rotateY('   + this._getItemValueWithoutSpace(selector, groupId, 'rotateY', '0deg') + ') ' +
                'rotateZ('   + this._getItemValueWithoutSpace(selector, groupId, 'rotateZ', '0deg') + ') '
        }

        let resolved = SingleCssValue.create(
            this.name,
            propertyValue
            );

        store.addSelectedPropertyResolverOption(selector, this.name, filterFunction);

        return [
            resolved
        ];
    }

    parseItem(selector, groupId, itemId, position, style){
        let filterFunction = this._parseFilterFunction(style);

        if(itemId === 'transform-function'){
            store.addSelectedPropertyResolverOption(selector, this.name, filterFunction);
            this._setItem(selector, groupId, 'transform-function', filterFunction);
            return filterFunction;
        }else if(itemId.indexOf(filterFunction) !== -1){
            let param = this._parseParam(style, position, filterFunction);
            this._setItem(selector, groupId, itemId, param);
            return param;
        }
    }

    _parseFilterFunction(style){
        if(!style){
            return null;
        }
        style = style + '';

        let filterFunction = style.split('(')[0].trim();

        return filterFunction.indexOf('rotate') === 0? 'rotate': filterFunction;
    }

    _parseSkew(style, position){
        let split = style.split('(');
        if(split.length < 2){
            return null;
        }

        split = split[1];
        split = split.replace(")", "");
        let param = cssParamParser.parseArgument(position, split, ",");

        return param;
    }

    _parseParam(style, position, filterFunction){
        if(!style){
            return null;
        }

        if(filterFunction === 'skew'){
            return this._parseSkew(style, position)
        }else{
            return this._parseRotate(style, position)
        }
    }


    _parseRotate(style, position) {
        return style.split("rotate")[position + 1].split("(")[1].split(")")[0]
    }
}

let transformResolver = new TransformResolver();
export default transformResolver;