<template>
    <div v-show="false">
        <div ref="plus">
            <font-awesome-icon icon="plus"></font-awesome-icon>
        </div>
        <div ref="tasks">
            <font-awesome-icon icon="tasks"></font-awesome-icon>
        </div>
        <div ref="minus">
            <font-awesome-icon icon="minus"></font-awesome-icon>
        </div>
    </div>

</template>

<script>

import MarginSetter from "@/components/styles/MarginSetter";
import store, {ViewMode} from "../../store/Store";
import bus from "../../bus/bus";
import busEvents from "../../bus/busEvents";
import css from "../../services/css";
import StyleSetEvent from "../../events/StyleSetEvent";
import ElementFocusedEvent from "../../events/ElementFocusedEvent";
import FocusedElementHighlightedEvent from "../../events/FocusedElementHighlightedEvent";
import selectorGenerator from "../../services/selectorGenerator";
import interactionRecorder from "../../services/InteractionRecorder";
import TaskEnteredEvent from "../../events/TaskEnteredEvent";

export default {
  name: 'Selector',
  components: {
    MarginSetter
  },

  data(){
    return {
        state: store.state,
        hoverOutline: null,
        focusedOutline: null,
        isFocusing: false,
        createTaskImage: null,
        selectSelectorImage: null,
        deselectSelectorImage: null,
        selectedElementsOutlines: [],
        latestTarget: null
    }
  },

  methods: {
      scrollIntoViewIfNeeded(target) {
          window.ttarget = target;
          if (target.getBoundingClientRect().bottom > window.innerHeight) {
              target.scrollIntoView(false);
          }

          if (target.getBoundingClientRect().top < 0) {
              target.scrollIntoView({behavior: "smooth"});
          }
      },

    createOutlineElement(className){
      let outline = document.createElement("div");
      document.body.appendChild(outline);

      let outlineInner = document.createElement("div");
      outline.appendChild(outlineInner)

      outlineInner.className = 'sharer-app-inner-outline';
      outline.className = 'sharer-app-outline ' + className;
      return outline;
    },

    createHoverOutline(){
        this.hoverOutline = this.createOutlineElement('sharer-app-hover-outline');
        let add = document.createElement("div");
        this.hoverOutline.appendChild(add);
        add.className = "add-icon";

        this.createTaskImage = document.createElement("div");
        this.createTaskImage.appendChild(this.createSVG('tasks'));
        add.appendChild(this.createTaskImage);

        this.selectSelectorImage = document.createElement("div");
        css.set(this.selectSelectorImage, 'display', 'none');
        this.selectSelectorImage.appendChild(this.createSVG('plus'));
        add.appendChild(this.selectSelectorImage);


        css.set(this.hoverOutline, 'display', 'none');

        this.deselectSelectorImage = document.createElement("div");
        css.set(this.deselectSelectorImage, 'display', 'none');
        this.deselectSelectorImage.appendChild(this.createSVG('minus'));
        add.appendChild(this.deselectSelectorImage);   },

    createSVG(name){
        let doc = new DOMParser().parseFromString(
            this.$refs[name].innerHTML, 'application/xml');
        return doc.documentElement;
    },

    createOutlines(){
      this.createHoverOutline();
      this.focusedOutline = this.createOutlineElement('sharer-app-focused-outline');
    },

    matchOutlineDimensionsWithElement(element, outline){
        //css.set(outline, 'display', 'none');
        //return

        let rect = element.getBoundingClientRect();

      //css.set(outline, 'display', 'block');
      outline.style.top = (rect.top  + window.scrollY)+ "px";
      outline.style.left = (rect.left + window.scrollX) + "px";
      outline.style.width = rect.width + "px";
      outline.style.height = rect.height + "px";
    },

    nodeContains(node, child){

        if(node === child){
            return true;
        }

        let that = this;
        let contains = false;
        node.childNodes.forEach(function(a) {
          contains = contains || that.nodeContains(a, child);
        });

        return contains;

    },

    createListeners(){

      let that = this;

      document.body.addEventListener('click', function(e){
        //if (e.target === store.state.$app || store.state.$app.contains(e.target)){
        if (e.target === store.state.$app || that.nodeContains(store.state.$app, e.target)){

        }else{

            if(!that.isFocusing){
                that.clearSelectedOutlines();
                return;
            }

            window.app = store.state.$app;
            window.target = e.target;
            store.setFocusedElement(e.target, true);

            if(store.state.selectedElements.indexOf(e.target) === -1){
                css.set(that.focusedOutline, 'display', 'none');
            }else{
                css.set(that.focusedOutline, 'display', 'block');
                that.matchOutlineDimensionsWithElement(that.state.focusedElement, that.focusedOutline)
            }

            e.preventDefault();


        }
      });

      document.body.addEventListener("mousemove", function(e){
        //console.dir(e);
        window.e = e;
        that.latestTarget = e.target;

        if (e.target === store.state.$app || that.nodeContains(store.state.$app, e.target)){
          that.hoverOutline.style.display="none";
          return;
        }

        if(that.isFocusing){
            that.hoverOutline.style.display = "block"
        }

        var elementToCover = e.target;
        that.matchOutlineDimensionsWithElement(elementToCover, that.hoverOutline);


        if(store.state.focusedTask){
          that.updateAddIcon()
        }

      });

        document.addEventListener("keyup", function (event) {
            if (event.keyCode === 17 || event.key === 'Control' || event.metaKey || event.ctrlKey) {
                that.isFocusing = false;
            }

            if(event.key === 'Escape'){
                store.defocusTask();
            }

        });

        document.addEventListener("keydown", function (event) {
            if (event.keyCode === 17 || event.key === 'Control'|| event.metaKey || event.ctrlKey) {
                that.isFocusing = true;
            }
        });


      bus.$on(ElementFocusedEvent.name, function (e) {
          if(!e.element){
              css.set(that.selectSelectorImage, 'display', 'none');
              css.set(that.createTaskImage, 'display', 'block');
          }else{
              css.set(that.selectSelectorImage, 'display', 'block');
              css.set(that.createTaskImage, 'display', 'none');
          }
      });

      bus.$on(StyleSetEvent.name, function(){
          css.set(that.focusedOutline, 'display', 'none');
          that.clearSelectedOutlines();
      });

      bus.$on(FocusedElementHighlightedEvent.name, function(){
          that.matchOutlineDimensionsWithElement(that.state.focusedElement, that.focusedOutline);
          that.scrollIntoViewIfNeeded(that.state.focusedElement);
      });
    },

      updateAddIcon(){
          if(store.state.selectedElements.indexOf(e.target) === -1){
              css.set(this.deselectSelectorImage, 'display', 'none');
              css.set(!store.state.focusedTask? this.createTaskImage: this.selectSelectorImage, 'display', 'block');
              css.set(store.state.focusedTask? this.createTaskImage: this.selectSelectorImage, 'display', 'none');
          }else{
              css.set(this.deselectSelectorImage, 'display', 'block');
              css.set(this.selectSelectorImage, 'display', 'none');
              css.set(this.createTaskImage, 'display', 'none');
          }
      },

       clearSelectedOutlines(){
          let outlines = this.selectedElementsOutlines;
          this.selectedElementsOutlines = [];
          for(let outline of outlines){
              console.dir(outline);
              document.body.removeChild(outline)
          }

          css.set(this.focusedOutline, 'display', 'none')

      },

      refreshSelectedOutlines(){
          let oldElements = this.selectedElementsOutlines;
          this.selectedElementsOutlines = [];
          for(let element of this.state.selectedElements){
              let outline = this.createOutlineElement('sharer-app-selected-outline');
              this.matchOutlineDimensionsWithElement(element, outline);
              this.selectedElementsOutlines.push(outline)
          }

          for(let outline of oldElements){
              console.dir(outline);
              document.body.removeChild(outline)
          }

          this.updateAddIcon()
      }

  },

    watch: {
        isFocusing(v){
          this.hoverOutline.style.display = v? 'block': 'none';
      },

        'state.focusedElement': function(v){
            if(!v){
                css.set(this.focusedOutline, 'display', 'none')
            }
        },

        /*'state.focusedTask': function(v){
            if(v){
                css.set(this.createTaskImage, 'display', 'none');
                css.set(this.selectSelectorImage, 'display', 'block');
            }else{
                css.set(this.createTaskImage, 'display', 'block');
                css.set(this.selectSelectorImage, 'display', 'none');
            }
        },*/

        'state.selectedElements': function(ar){

            this.refreshSelectedOutlines();
        },

        'state.viewMode': function(v){
            if(v === ViewMode.inspect){
                //this.clearSelectedOutlines();
            }
        }
    },

  mounted(){
    this.createOutlines();
    this.createListeners();

    bus.$on(TaskEnteredEvent.name, () => this.refreshSelectedOutlines())

  }
}
</script>


<style  lang="scss">


  .sharer-app-outline{
    border: 5px dashed #DD1717;
    position: absolute;
    z-index: 2147483643;
    pointer-events: none;
  }

  .add-icon{
      height: 42px;
      width: 42px;
      background: white;
      padding: 7px;
      border-radius: 50%;
      position: absolute;
      bottom: -25px;
      left: 5px;
      border: 2px solid black;
      text-align: center;
  }

  .sharer-app-focused-outline{
    border: 5px dashed #DD1717;
      z-index: 2147483644;
  }

  .sharer-app-focused-outline .sharer-app-inner-outline{
    background: #FF4949;
    opacity: 0.2;
    height: 100%;
    width: 100%;
      position: relative;
  }

    .sharer-app-selected-outline{
        z-index: 2147483644;
        border: 5px dashed #0F4392;

    }

  .sharer-app-hover-outline{
      z-index: 2147483645;
  }

</style>

