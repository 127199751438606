
import css from "@/services/css"
import modificationDao from "../daos/ModificationDao";

import busEvents from "../bus/busEvents";
import bus from "../bus/bus";
import selectorGenerator from "../services/selectorGenerator";
import StyleSetEvent from "../events/StyleSetEvent";
import store from "../store/Store";
export default {
    name: 'Category',
    components: {
    },
    props: {

    },

    data(){
        return {

        }
    },

    computed: {
      groupId(){
          return store.state.focusedTask? store.state.focusedTask.uuid : null;
      }
    }
}

