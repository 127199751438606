<template>
    <div>
        Width
        <slider-units-picker
            @reset="onReset"
            @undo="onUndo"
            @redo="onRedo"
            :max="1000"
            v-model="selectedValue"></slider-units-picker>

    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";

export default {
    name: 'WidthSetter',
    components: {SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'width'
        }
    },

    methods: {

    },

    async mounted() {
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
