<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Typography</h2>
    </div>

    <editable-setter :el="el" :group-id="groupId"></editable-setter>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
      <text-transform-setter :el="el" :group-id="groupId"></text-transform-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
      <font-family-setter :el="el" :group-id="groupId"></font-family-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
      <column-count-setter :el="el" :group-id="groupId"></column-count-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
      <letter-spacing-setter :el="el" :group-id="groupId"></letter-spacing-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
      <font-size-setter :el="el" :group-id="groupId"></font-size-setter>
    </div>


  </div>
</template>

<script>
 import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import EditableSetter from "../styles/typography/EditableSetter";
 import FontSizeSetter from "../styles/typography/FontSizeSetter";

export default {
  name: 'TypographyCategory',
  components: {
    FontSizeSetter,
    EditableSetter,
    LetterSpacingSetter,
    TextTransformSetter,
    ColumnCountSetter,
    FontFamilySetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
