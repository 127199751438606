export default class Interaction {

    viewUuid;
    taskUuid;
    type;
    selector;
    property;
    value;

    constructor(viewUuid, taskUuid, type, selector, property, value) {
        this.viewUuid = viewUuid;
        this.type = type;
        this.selector = selector;
        this.property = property;
        this.value = value;
        this.taskUuid = taskUuid;
    }

}