<template>
    <div>
        <list-picker
                label="Position"
                v-model="selectedValue"
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                :property-name="propertyName"
                :options="options"
                :is-showing-pretty="true"
        />
    </div>
</template>

<script>

import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import TextPicker from "../TextPicker";
import ListPicker from "../ListPicker";
import loadedFontFinder from "../../../services/LoadedFontFinder";

//Array.from($0.querySelectorAll('td:first-child')).map(x => x.innerText)

export default {
    name: 'PositionSetter',
    components: {ListPicker, TextPicker},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'position',
            options: ["static", "relative", "fixed", "absolute", "sticky"]
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
