import SingleCssValue from "../../models/SingleCssValue";
import Resolver from "./Resolver";

class TextShadowResolver extends Resolver{

    name = 'text-shadow';
    propertyName = 'text-shadow';

    _doResolve(selector, groupId, itemName, value){

        //alert(this._getItemValue(selector, groupId, 3, '#000000'))
        //alert(value)
        //alert(itemName)

        let resolved = SingleCssValue.create(
            this.name,
            this._getItemValue(selector, groupId, 0, '0px')
             + this._getItemValue(selector, groupId, 1, '0px')
             + this._getItemValue(selector, groupId, 2, '1px')
             + this._getItemValue(selector, groupId, 3, '#000000')
            );


        return [
            resolved
        ];
    }

    //_getItemValue(selectorItems, itemName, defaultValue){
    //    let value = selectorItems[itemName] || defaultValue;
    //    return value + ' ';
    //}
}

let textShadowResolver = new TextShadowResolver();
export default textShadowResolver;