import css from "@/services/css"
import store from "../../store/Store";

class TaskCommentUpdatedMessageHandler{

    /**
     *
     * @param taskComment {TaskComment}
     */
    handle(taskComment){
        store.addComment(taskComment);

    }
}

let taskCommentUpdatedMessageHandler = new TaskCommentUpdatedMessageHandler();
export default taskCommentUpdatedMessageHandler;
