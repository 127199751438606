<template>
    <setter-layout>
        <div class="flex justify-between">
            <div>
                Edit Content
            </div>
            <div>
                <input type="checkbox" v-model="checked" @input="onChecked" >
            </div>
        </div>
    </setter-layout>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import ListPicker from "../ListPicker";
import SetterLayout from "../SetterLayout";
import editableObserver from "../../../services/EditableObserver";
import bus from "../../../bus/bus";
import EditableInputted from "../../../events/EditableInputted";
import store from "../../../store/Store";
import selectorGenerator from "../../../services/selectorGenerator";
import InteractionType from "../../../services/InteractionType";
import interactionRecorder from "@/services/InteractionRecorder";


export default {
    name: 'EditableSetter',
    components: {SetterLayout, ListPicker},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
            options: [],
            type: 'option',
            propertyName: 'editableContent',
            checked: false,
            state: store.state
        }
    },

    methods: {
        onChecked(v){
            editableObserver.setGroupOption(this.selector, this.groupId, v.target.checked)
        }

    },

    watch: {
        "state.focusedElement": function(newEl, oldEl){
            // alert(store.state.focusedTask.uuid + " " + this.groupId + " " + selectorGenerator.get(newEl) + " " +  this.selector)
            if(store.state.focusedTask && store.state.focusedTask.uuid === this.groupId && selectorGenerator.get(newEl) === this.selector){
                // alert("setting " +  !!interactionRecorder.getPropertyValue(this.selector, this.groupId, 'isEditable'))
                newEl.contentEditable = !!interactionRecorder.getPropertyValue(this.selector, this.groupId, 'isEditable');
            }
        },
        selector(){
            this.checked = interactionRecorder.getPropertyValue(this.selector, this.groupId, 'isEditable');
        }
    },

    async mounted() {
        let that = this;
        bus.$on(EditableInputted.name, function(e){
            if(e.selector === that.selector && e.groupId === that.groupId){
                that.selectedValue = e.input;
                interactionRecorder.record(that.selector, that.groupId, 'editableContent', InteractionType.OPTION, e.input, e.input)
                store.state.taskStats[that.groupId].changes = interactionRecorder.getNumberOfGroupPropertyValues(that.groupId)

            }
        });
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
