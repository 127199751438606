<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Position</h2>
    </div>
    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3">
      <position-setter :el="el" :group-id="groupId"></position-setter>
      <top-setter :el="el" :group-id="groupId"></top-setter>
      <bottom-setter :el="el" :group-id="groupId"></bottom-setter>
      <left-setter :el="el" :group-id="groupId"></left-setter>
      <right-setter :el="el" :group-id="groupId"></right-setter>
    </div>
  </div>
</template>

<script>

import vClickOutside from 'v-click-outside'
import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
import PositionSetter from "../styles/position/PositionSetter";
import TopSetter from "../styles/position/TopSetter";
import BottomSetter from "../styles/position/BottomSetter";
import LeftSetter from "../styles/position/LeftSetter";
import RightSetter from "../styles/position/RightSetter";

export default {
  name: 'PositionCategory',
  components: {
    RightSetter,
    LeftSetter,
    BottomSetter,
    TopSetter,
    PositionSetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },

  mounted(){
  }
}
</script>


<style lang="scss">



</style>
