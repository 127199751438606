import SingleCssValue from "../../models/SingleCssValue";
import store from "../../store/Store";
import Resolver from "./Resolver";
import {SpacingAllResolver} from "./SpacingAllResolver";

class PaddingAllResolver extends SpacingAllResolver{

    name = 'padding-all';
    prefix = 'padding';

}


let paddingAllResolver = new PaddingAllResolver();
export default paddingAllResolver;