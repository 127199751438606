import SingleCssValue from "../../models/SingleCssValue";
import store from "../../store/Store";
import Resolver from "./Resolver";

class BoxShadowResolver extends Resolver{

    name = 'box-shadow';
    propertyName = 'box-shadow';

    _doResolve(selector, groupId){

        let resolved = SingleCssValue.create(
            this.name,
            this._getItemValue(selector, groupId, 0, '0px')
             + this._getItemValue(selector, groupId, 1, '0px')
             + this._getItemValue(selector, groupId, 2, '1px')
             + this._getItemValue(selector, groupId, 3, '1px')
             + this._getItemValueWithoutSpace(selector, groupId, 4, '#000000')
            );

        store.addSelectedPropertyResolverOption(selector, this.name, 'active');


        return [
            resolved
        ];
    }


}


let boxShadowResolver = new BoxShadowResolver();
export default boxShadowResolver;