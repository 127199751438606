import SingleCssValue from "../../models/SingleCssValue";
import store from "../../store/Store";
import Resolver from "./Resolver";

export class SpacingAllResolver extends Resolver{

    prefix = '';

    _doResolve(selector, groupId, itemId, value){

        let resolved = [
            SingleCssValue.create(  this.prefix + '-top', value),
            SingleCssValue.create( this.prefix + '-right', value),
            SingleCssValue.create( this.prefix + '-bottom', value),
            SingleCssValue.create( this.prefix + '-left', value)
            ];

        return resolved;
    }

}

