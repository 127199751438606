import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Echo from 'laravel-echo'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faPaintBrush, faEllipsisH, faSlidersH, faUserSecret, faArrowLeft, faTimesCircle, faCaretDown, faTimes, faPen, faCheck, faPlus, faMinus, faTasks, faUndo, faTrashAlt, faRedo} from '@fortawesome/free-solid-svg-icons'
import {faStar} from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vClickOutside from 'v-click-outside'
import VuePluralize from 'vue-pluralize'
import vSelect from 'vue-select'
import _ from 'sharerui/src/main'


import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider);

Vue.component('v-select', vSelect);

library.add(faUserSecret, faPaintBrush, faEllipsisH, faSlidersH, faArrowLeft, faCaretDown, faTimesCircle, faStar, faTimes, faPen, faCheck, faPlus, faMinus, faTasks, faUndo, faRedo, faTrashAlt);


Vue.directive('visible', function(el, binding) {
  el.style.visibility = !!binding.value ? 'visible' : 'hidden';
});

Vue.use(VuePluralize);

Vue.component('font-awesome-icon', FontAwesomeIcon)
//Vue.use(vClickOutside);

Vue.config.productionTip = false;
window.Pusher = require('pusher-js');

import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

let app = document.createElement('collab-rocket');
document.body.appendChild(app);

/**
new Vue({
  router,npm run build

  render: h => h(App)
}).$mount('#app');
**/

Vue.customElement('collab-rocket', App);


