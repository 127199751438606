<template>
    <div>
        <color-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                label="Text Shadow Color"
                :property-name="propertyName"
                v-model="selectedValue" />
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import Chrome from 'vue-color/src/components/Chrome.vue';
import tinycolor from 'tinycolor2';
import ColorPicker from "../ColorPicker";
import StyleSetter from "../../../mixin/StyleSetter";
import cssParamParser from "../../../services/cssParamParser";
import boxShadowResolver from "../../../services/propertyresolver/boxShadowResolver";


export default {
    name: 'BoxShadowColorSetter',
    components: {ColorPicker, Chrome},
    mixins: [StyleSetter],
    data(){
        return {
            resolver: boxShadowResolver,
            itemId: 4,
        }
    },

  methods: {

    mapStyleToSelectedValue(v){
      return v && tinycolor(v).toHex8() !== '00000000'?  tinycolor(v).toHex8String(): '#ffffff'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
