<template>
  <div class="hello">
    <h2>
      Margin

      Margin-top r
    </h2>
    <vue-slider v-model="selectedValue" />

  </div>

</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import axios from 'axios'
import modificationDao from "../../daos/ModificationDao";
import _ from 'lodash'
import StyleSetter from "../../mixin/StyleSetter";

export default {
  name: 'MarginSetter',
  components: {VueSlider},
  mixins: [StyleSetter],
  data(){
    return {
      selectedValue: 0,
      propertyName: 'margin-top'
    }
  },

  methods: {
    mapSelectedValueToStyle(v){
      return  v + 'px'
    },

    mapStyleToSelectedValue(v){
      return window.parseInt(v);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
