<template>
    <div>
        Box Shadow Spread Position
        <slider-units-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :max="100"
                :min="0"
                v-model="selectedValue"></slider-units-picker>
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";
import cssParamParser from "../../../services/cssParamParser";
import boxShadowResolver from "../../../services/propertyresolver/boxShadowResolver";

export default {
    name: 'BoxShadowSpreadSetter',
    components: {SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
            resolver: boxShadowResolver,
            itemId: 3,
        }
    },

    methods: {
    },

    async mounted() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
