export default class OptionSet{

    groupId;

    options = {};

    constructor(groupId) {
        this.groupId = groupId;
    }

    setOption(optionName, optionValue){
        this.options[optionName] = optionValue;
    }

    getOption(optionName){
        return this.options[optionName];
    }
}