import axios from 'axios';
import Handler from "sharerui/src/action/Handler";
import missionControlMapper from "../../contract/mapper/MissionControlMapper";
class GetMissionControlHandler extends Handler{

    /**
     *
     * @param {GetMissionControl} query
     * @returns {Promise<MissionControl>}
     */
    async handle(query){
        let model = await axios.get(
            window._collabRocket.apiUrl + '/api/project/view/mission/' + query.viewId,
            {
                withCredentials: true
            }
        );

        console.dir(model);
        return missionControlMapper.map(model.data);
    }
}

let getMissionControlHandler = new GetMissionControlHandler();
export default getMissionControlHandler;
