<template>
    <setter-layout v-if="store.hasSelectedPropertyResolverOption(selector, resolver.name, itemId)">
        <div>
            Brightness Percentage
            <slider-units-picker
                    name="bs"
                    @reset="onReset"
                    @undo="onUndo"
                    @redo="onRedo"
                    :max="200"
                    :units="['%']"
                    v-model="selectedValue"></slider-units-picker>
        </div>
    </setter-layout>

</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";
import store from "../../../store/Store";
import SetterLayout from "../SetterLayout";
import filterResolver from "../../../services/propertyresolver/filterResolver";


export default {
    name: 'BrightnessPercentageSetter',
    components: {SetterLayout, SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
            itemId: 'brightness',
            resolver: filterResolver,
            store: store
        }
    },

    methods: {

    },

    async mounted() {
    },

    watch:{
    }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
