<template>
    <div>
        <list-picker
                label="Filter Function"
                v-model="selectedValue"
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                :property-name="propertyName"
                :options="options"
        />
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import ListPicker from "../ListPicker";
import filterResolver from "../../../services/propertyresolver/filterResolver";

export default {
    name: 'FilterFunctionSetter',
    components: {ListPicker},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
            itemId: 'filter-function',
            resolver: filterResolver,
            options: []
        }
    },

    methods: {
    },

    async mounted() {
      this.options = [
          "blur", "brightness", "contrast", // "drop-shadow",
          "grayscale", "hue-rotate", "invert", // "opacity",
          "saturate", "sepia"
      ]
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
