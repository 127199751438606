export default class SingleCssValue{

    name;
    value;


    constructor(property, value) {
        this.name = property;
        this.value = value;
    }

    static create(property, value){
        return new SingleCssValue(property, value);
    }
}