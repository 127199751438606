<template>

    <span><img
            :class="classNames"
            :src="src" ></span>
</template>

<script>

    export default {
        name: 'MyImage',
        props: {
            src: {
                type: String
            },
            classNames: {}
        }

    }
</script>

