<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Filters</h2>
    </div>
    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <opacity-setter :el="el" :group-id="groupId"></opacity-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <filter-function-setter :el="el" :group-id="groupId"></filter-function-setter>
    </div>

      <sepia-percentage-setter :el="el" :group-id="groupId"></sepia-percentage-setter>
      <saturate-percentage-setter :el="el" :group-id="groupId"></saturate-percentage-setter>
      <blur-percentage-setter :el="el" :group-id="groupId"></blur-percentage-setter>
      <brightness-percentage-setter :el="el" :group-id="groupId"></brightness-percentage-setter>
      <contrast-percentage-setter :el="el" :group-id="groupId"></contrast-percentage-setter>
      <grayscale-percentage-setter :el="el" :group-id="groupId"></grayscale-percentage-setter>
      <hue-rotate-degree-setter :el="el" :group-id="groupId"></hue-rotate-degree-setter>
      <invert-percentage-setter :el="el" :group-id="groupId"></invert-percentage-setter>

  </div>
</template>

<script>
 import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import OpacitySetter from "../styles/filter/OpacitySetter";
 import FilterFunctionSetter from "../styles/filter/FilterFunctionSetter";
 import SepiaPercentageSetter from "../styles/filter/SepiaPercentageSetter";
 import SaturatePercentageSetter from "../styles/filter/SaturatePercentageSetter";
 import BlurPercentageSetter from "../styles/filter/BlurPercentageSetter";
 import BrightnessPercentageSetter from "../styles/filter/BrightnessPercentageSetter";
 import ContrastPercentageSetter from "../styles/filter/ContrastPercentageSetter";
 import GrayscalePercentageSetter from "../styles/filter/GrayscalePercentageSetter";
 import HueRotateDegreeSetter from "../styles/filter/HueRotateDegreeSetter";
 import InvertPercentageSetter from "../styles/filter/InvertPercentageSetter";

export default {
  name: 'FilterCategory',
  components: {
    InvertPercentageSetter,
    HueRotateDegreeSetter,
    GrayscalePercentageSetter,
    ContrastPercentageSetter,
    BrightnessPercentageSetter,
    BlurPercentageSetter,
    SaturatePercentageSetter,
    SepiaPercentageSetter,
    FilterFunctionSetter,
    OpacitySetter,
    LetterSpacingSetter,
    TextTransformSetter,
    ColumnCountSetter,
    FontFamilySetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
