import { render, staticRenderFns } from "./RotateYPercentageSetter.vue?vue&type=template&id=598a6c0a&scoped=true&"
import script from "./RotateYPercentageSetter.vue?vue&type=script&lang=js&"
export * from "./RotateYPercentageSetter.vue?vue&type=script&lang=js&"
import style0 from "./RotateYPercentageSetter.vue?vue&type=style&index=0&id=598a6c0a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "598a6c0a",
  null
  
)

export default component.exports