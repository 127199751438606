<template>
    <setter-layout v-if="store.hasSelectedPropertyResolverOption(selector, resolver.name, 'rotate')">
        <div>
            Rotate Y Degrees
            <slider-units-picker
                    @reset="onReset"
                    @undo="onUndo"
                    @redo="onRedo"
                    :max="360"
                    :min="0"
                    :units="['deg']"
                    v-model="selectedValue"></slider-units-picker>
        </div>
    </setter-layout>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";
import store from "../../../store/Store";
import SetterLayout from "../SetterLayout";
import transformResolver from "../../../services/propertyresolver/TransformResolver";

export default {
    name: 'RotateYPercentageSetter',
    components: {SetterLayout, SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
            itemPosition: 1,
            itemId: 'rotateY',
            resolver: transformResolver,
            store: store
        }
    },

    methods: {

    },

    async mounted() {
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
