import bus from "../../bus/bus";
import Task from "sharerui/src/models/Task";
import DeselectElementAction from "../action/DeselectElementAction";
import DeselectElement from "../../action/contract/command/DeselectElement";
import SelectElement from "../../action/contract/command/SelectElement";
import SelectElementAction from "../action/SelectElementAction";
import actionQueue from "sharerui/src/service/ActionQueue"
import ElementFocusedEvent from "../../events/ElementFocusedEvent";
import selectorGenerator from "../selectorGenerator";
import store from "../../store/Store";
import css from "../css";



class ElementFocusedListener{


    startListening(){

        let that = this;

        bus.$on(ElementFocusedEvent.name, function(event){
            if(!event.isManualSelection){
                return
            }

            that._handleSelectedElements(event);
            that._handleTaskSelection(event);
            that._updateTaskStats();
        });
    }

    _handleTaskSelection(event){
        if(store.state.focusedTask == null){
            this._createNewTask(event);
        }else{
            let selector = selectorGenerator.get(event.element);

            if(interactionRecorder.hasInteractionForSelector(selector, store.state.focusedTask.uuid)){
                this._deselectElement(event, selector)
            }else{
                this._selectElement(event, selector)
            }
        }
    }

    _deselectElement(event, selector){
        interactionRecorder.deselect(selector, store.state.focusedTask.uuid);

        if(store.state.focusedTask.isSaved) {
            actionQueue.add(new DeselectElementAction(new DeselectElement(
                selector,
                window._collabRocket.viewUuid,
                store.state.focusedTask.uuid
            )));
        }

        //css.refreshAllCss()
    }

    _selectElement(event, selector){
        interactionRecorder.select(selector, store.state.focusedTask.uuid);

        if(store.state.focusedTask.isSaved){
            actionQueue.add(new SelectElementAction(new SelectElement(
                selector,
                window._collabRocket.viewUuid,
                store.state.focusedTask.uuid
            )));
        }

    }

    _createNewTask(event){
        let task = Task.newUnsavedTask(window._collabRocket.viewUuid, store.state.currentUser);
        interactionRecorder.select(selectorGenerator.get(event.element), task.uuid);
        store.addNewTask(task)
    }

    _handleSelectedElements(event){
        console.log(store.state.selectedElements)
        if(store.state.selectedElements.indexOf(event.element) === -1){
            store.state.selectedElements.push(event.element);
        }else{
            store.state.selectedElements.splice(store.state.selectedElements.indexOf(event.element), 1);
        }
        console.log(store.state.selectedElements)
    }

    _updateTaskStats() {
        store.state.taskStats[store.state.focusedTask.uuid].elements = store.state.selectedElements.length;
        store.state.taskStats[store.state.focusedTask.uuid].changes = interactionRecorder.getNumberOfGroupPropertyValues(store.state.focusedTask.uuid)
    }
}


let elementFocusedListener = new ElementFocusedListener();
export default elementFocusedListener;