import SingleCssValue from "../../models/SingleCssValue";
import store from "../../store/Store";
import Resolver from "./Resolver";
import {SpacingAllResolver} from "./SpacingAllResolver";

class MarginAllResolver extends SpacingAllResolver{

    name = 'margin-all';
    prefix = 'margin';

}


let marginAllResolver = new MarginAllResolver();
export default marginAllResolver;