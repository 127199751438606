import PropertyInteractionRecord from "./PropertyInteractionRecord";
import _ from "lodash";
import css from "./css";
import OptionSet from "../models/OptionSet";

export default class SelectorInteractionRecord{

    _selector;

    /**
     *
     * @type {{string: PropertyInteractionRecord}}
     * @private
     */
    propertyRecords = {};

    /**
     * Used to indicate a selector is selected for a group without an associated property change
     * @type {{Number :boolean}}
     */
    groupSelections = {};

    /**
     *
     * @type {{string: OptionSet}}
     * @private
     */
    groupOptionSets = {};

    /**
     *
     * @type {{string: OptionSet}}
     * @private
     */
    setGroupOption(groupId, optionName, optionValue){
        if(!this.groupOptionSets[groupId]){
            this.groupOptionSets[groupId] = new OptionSet(groupId);
        }

        this.groupOptionSets[groupId].setOption(optionName, optionValue);
    }

    hasGroupOptionForOption(groupId, optionName){
        return this.groupOptionSets[groupId] && this.groupOptionSets[groupId].getOption(optionName);
    }

    getGroupOption(groupId, optionName){
        if(!this.groupOptionSets[groupId]){
            this.groupOptionSets[groupId] = new OptionSet(groupId);
        }

        return this.groupOptionSets[groupId].getOption(optionName);
    }

    constructor(selector){
        this._selector = selector;
        this.propertyRecords = {}
    }



    select(groupId){
        this.groupSelections[groupId] = true;
    }

    deselect(groupId){
        if(this.groupSelections[groupId]){
            delete this.groupSelections[groupId];
        }

        for (let propertyRecord of Object.values(this.propertyRecords)){
            propertyRecord.deselect(groupId);
        }
    }

    get selector(){
        return this._selector;
    }

    undoPropertyValue(groupId, propertyName){
        if(this.propertyRecords[propertyName]){
            this.propertyRecords[propertyName].undoPropertyValue(groupId)
        }
    }

    redoPropertyValue(groupId, propertyName){
        if(this.propertyRecords[propertyName]){
            this.propertyRecords[propertyName].redoPropertyValue(groupId)
        }
    }

    record(propertyName, groupId, type, oldPropertyValue, newPropertyValue){

        if(!this.propertyRecords[propertyName]){
            this.propertyRecords[propertyName] = new PropertyInteractionRecord(this._selector, propertyName, type, oldPropertyValue);
        }

        this.propertyRecords[propertyName].setPropertyValue(groupId, newPropertyValue);
    }

    hasInteractionForGroup(groupId){
        if(this.groupSelections[groupId]){
           return true;
        }

        for (let propertyRecord of Object.values(this.propertyRecords)){
            if(propertyRecord.hasPropertyValueForGroup(groupId)){
                return true;
            }
        }

        return false;
    }

    hasInteractionForAnyGroup(groupIds){
        for(let groupId of groupIds){
            if(this.hasInteractionForGroup(groupId)){
                return true;
            }
        }

        return false;
    }

    hasInteractionForGroupByPropertyName(groupId, propertyName){
        return this.propertyRecords[propertyName] && this.propertyRecords[propertyName].hasPropertyValueForGroup(groupId)
    }

    hasPropertyValueForGroup(groupId){

        for (let propertyRecord of Object.values(this.propertyRecords)){
            if(propertyRecord.hasPropertyValueForGroup(groupId)){
                return true;
            }
        }

        return false;
    }

    getPropertyValuesForGroupId(groupId, type){
        return this.getPropertyValues((id) => 1, (id) => id == groupId, type)
    }

    /**
     *
     * @param priorityFunction
     * @param activePredicate
     * @param type
     * @returns {{String:String}}
     */
    getPropertyValues(priorityFunction, activePredicate, type) {
        let values = {};
        for(let [propertyName, propertyRecord] of Object.entries(this.propertyRecords)){
            if(propertyRecord.type === type){
                values[propertyName] = propertyRecord.getPropertyValue(priorityFunction, activePredicate)
            }
        }

        return values;
    }
}