export default class InteractionDescriptor{


    uuid;

    taskUuid;

    selector;

    type;

    property;

    value;


    constructor(uuid, taskUuid, selector, type, property, value) {
        this.uuid = uuid;
        this.taskUuid = taskUuid;
        this.selector = selector;
        this.type = type;
        this.property = property;
        this.value = value;
    }
}
