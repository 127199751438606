import Task from 'sharerui/src/models/Task'
import TaskStats from "../models/TaskStats";
import Vue from 'vue'
import {Store as BaseStore} from "sharerui/src/store/Store";
import {default as baseStore} from "sharerui/src/store/Store";
import bus from "../bus/bus";
import ElementFocusedEvent from "../events/ElementFocusedEvent";

class Store extends BaseStore{

    state = Object.assign(baseStore.state, {

        isMentionOpen: false,
        $app: null,
        focusedElement: null,
        selectedElements: [],
        viewMode: ViewMode.tasks,
        focusedTask: null,
        highlightFocusedElement: true,

        activeInteractionGroupIds: {},
        isLoggedIn: false,
        isShowingMissionControl: true,

        /**
         * @type {{string: TaskStats}}
         */
        taskStats: {},

        /**
         * @type {Task[]}
         */
        tasks: [],

        currentUser: null,

        selectedPropertyResolverOptions: {}
    });

    addSelectedPropertyResolverOption(selector, name, value){
        if(!this.state.selectedPropertyResolverOptions[selector]){
            Vue.set(this.state.selectedPropertyResolverOptions, selector, {});
        }

        Vue.set(this.state.selectedPropertyResolverOptions[selector], name, value);

    }


    hasSelectedPropertyResolverOption(selector, name, value){
        if(!this.state.selectedPropertyResolverOptions[selector]){
            Vue.set(this.state.selectedPropertyResolverOptions, selector, {});
        }

        return this.state.selectedPropertyResolverOptions[selector][name] === value;

    }

    setFocusedElement(el, isManualSelection = false){
        bus.$emit(ElementFocusedEvent.name, new ElementFocusedEvent(el, this.state.focusedElement, isManualSelection));
        this.state.focusedElement = el;
    }

    addTasks(tasks){
        for(let task of tasks){
            this.state.tasks.push(task);
        }
    }

    deleteTask(task){
        let index = this.state.tasks.indexOf(task);
        if (index !== -1) {
            this.state.tasks.splice(index, 1);
        }
    }

    isGroupActive(groupId){
        return !!this.state.activeInteractionGroupIds[groupId];
    }

    areAllGroupsActive(){

        for(let task of this.state.tasks){
            if(!this.state.activeInteractionGroupIds[task.uuid]){
                return false;
            }
        }

        return true;
    }

    addAllGroupsToActiveInteractionGroupIds(){
        for(let task of this.state.tasks){
            this.addActiveInteractionGroupId(task.uuid)
        }
    }

    removeAllGroupsToActiveInteractionGroupIds(){
        Vue.set(this.state, 'activeInteractionGroupIds', {});

    }

    addActiveInteractionGroupId(groupId){
        Vue.set(this.state.activeInteractionGroupIds, groupId, true);
    }

    removeActiveInteractionGroupId(groupId){
        Vue.delete(this.state.activeInteractionGroupIds, groupId);
    }

    addNewTask(task){
        Vue.set(this.state.taskStats, task.uuid, new TaskStats());
        Vue.set(this.state.comments, task.uuid, []);
        this.state.tasks.unshift(task);
        this.state.focusedTask = task;
    }

    isTaskFocusedByIndex(taskIndex){
        return this.state.focusedTask === this.state.tasks[taskIndex]
    }

    getCommentsByTaskIndex(taskIndex){
        return this.state.comments[this.getTaskByIndex(taskIndex).uuid];
    }

    closeInspectMode(){
        this.state.viewMode = ViewMode.tasks;
        //this.state.focusedElement = null;
        //this.state.focusedTask = null;
    }

    focusTask(task){

        this.setFocusedElement(null);
        this.state.focusedTask = task;
        //this.addActiveInteractionGroupId(task.uuid);

        this._removeUnsavedTasks(task)
    }

    defocusTask(){
        this.state.viewMode = ViewMode.tasks;
        this.setFocusedElement(null);
        this.state.focusedTask = null;
        this.state.selectedElements = [];

        this._removeUnsavedTasks()
    }

    _removeUnsavedTasks(focusedTask){
        for(let task of this.state.tasks){
            if(!task.isSaved && task !== focusedTask){
                this.state.tasks.splice(this.state.tasks.indexOf(task), 1);
            }
        }
    }

    getTaskByIndex(taskIndex){
        return this.state.tasks[taskIndex]
    }

    getTaskByIndexAssigneeName(taskIndex){
        if(this.state.tasks.length - 1 < taskIndex){
            return null;
        }else if(this.getTaskByIndex(taskIndex).assignee){
            return this.getTaskByIndex(taskIndex).assignee.name;
        }else{
            return undefined;
        }
    }

    getTaskByIndexSwimlaneName(taskIndex){
        if(this.state.tasks.length - 1 < taskIndex){
            return null;
        }else if(this.getTaskByIndex(taskIndex).swimlane){
            return this.getTaskByIndex(taskIndex).swimlane.name;
        }else{
            return undefined;
        }
    }

    getTaskStatsByIndex(taskIndex){
        return this.state.taskStats[this.getTaskByIndex(taskIndex).uuid];
    }

    addCommentByTaskIndex(taskIndex, comment) {
        this.state.comments[this.getTaskByIndex(taskIndex).uuid].push(comment)
    }



    /**
     *
     * @param taskComment {TaskComment}
     *
    addComment(taskComment){
        if(!this.state.comments[taskComment.taskUuid]){
            Vue.set(this.state.comments, taskComment.taskUuid, []);
        }

        let existingComment = _.find(this.state.comments[taskComment.taskUuid], (t) => t.uuid === taskComment.uuid);
        if(existingComment){
            existingComment.content = taskComment.content;
        }else{
            this.state.comments[taskComment.taskUuid].push(taskComment)
        }
    }*/

    /**
     *
     * @param task {Task}
     */
    addTask(task){

        let existing = _.find(this.state.tasks, (t) => t.uuid === task.uuid);
        if(existing){
            existing.consume(task);
        }else{
            Vue.set(this.state.taskStats, task.uuid,  new TaskStats());
            Vue.set(this.state.comments, task.uuid, []);
            this.state.tasks.unshift(task);
        }
    }
}

export class ViewMode{
    static inspect = 'inspect';
    static tasks = 'tasks';
}

let store = new Store();
export default store;