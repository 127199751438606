<template>
    <div>
        Letter Spacing



        <slider-units-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :max="5"
                :type="'Length'"
                :options="['normal', 'initial']"
                v-model="selectedValue"></slider-units-picker>

    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import axios from 'axios'
import modificationDao from "../../daos/ModificationDao";
import _ from 'lodash'
import StyleSetter from "../../mixin/StyleSetter";
import Chrome from 'vue-color/src/components/Chrome.vue';
import tinycolor from 'tinycolor2';
import ColorPicker from "./ColorPicker";
import TextPicker from "./TextPicker";
import ImagePicker from "./ImagePicker";
import ListPicker from "./ListPicker";
import loadedFontFinder from "../../services/LoadedFontFinder";
import SliderPicker from "./SliderPicker";
import DropdownListSelect from "sharerui/src/components/ui/DropdownListSelect";
import SliderUnitsPicker from "./SliderUnitsPicker";

export default {
    name: 'LetterSpacingSetter',
    components: {SliderUnitsPicker, SliderPicker, TextPicker, DropdownListSelect},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'letter-spacing',
        }
    },

    methods: {

      /*  mapSelectedValueToStyle(v){
            return  v;
        },

        mapStyleToSelectedValue(v){
            return v;
            // return window.parseInt(v);
        },*/

    },

    async mounted() {
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
