<template>



  <picker
          :label="label"
          :isInputting="isShowingInput"
          :input="input"
          @reset="$emit('reset')"
          @undo="$emit('undo')"
          @redo="$emit('redo')"

  >


    <template v-slot:actions>
      <div class="w-6" v-visible="!input && !isInputting && !isShowingInputAlways" @click="isInputting = true">
        <font-awesome-icon icon="plus"></font-awesome-icon>
      </div>
    </template>

    <template v-slot:input="params">
      <slot name="input" :input="params.input">
          {{ params.input }}
      </slot>
    </template>

    <template v-slot:inputting>

        <slot name="inputting">
            <div class="w-full text-sm mr-5 h-6 text-grey-500">
                <input
                        class="border h-6 py-2 px-3 w-full rounded text-sm" type="text"
                        v-model="text" >
            </div>
        </slot>


    </template>


          <template  v-slot:input-actions>

              <div v-if="!isShowingInputAlways">
                  <div class="text-right h-6 w-6 ml-1" v-show="isInputting" @click="onBlur">
                      <font-awesome-icon icon="check"></font-awesome-icon>
                  </div>
                  <div class="text-right h-6 w-6 ml-1"  v-show="!isInputting && input" @click="isInputting = true">
                      <font-awesome-icon icon="pen"></font-awesome-icon>
                  </div>
              </div>
          </template>


  </picker>

</template>


<script>
  import 'vue-slider-component/theme/antd.css'
  import Chrome from 'vue-color/src/components/Chrome.vue';
  import vClickOutside from 'v-click-outside'
  import Picker from "./Picker";


  export default {
    name: 'TextPicker',
    components: {Picker, Chrome},
    props: {
      value: {},
      label: String,
        isShowingInputAlways: {
          default: false
        }
    },

      computed: {
          isShowingInput(){
            return this.isInputting || this.isShowingInputAlways;
          }
      },


    data(){
      return {
        isInputting: false,
        input: this.value,
        text: this.value
      }
    },

    watch: {
      input(v){
        this.$emit('input', this.input)
      },

        value(v){
          this.input = v;
         }
    },
    methods: {

      onBlur(){
        if(this.isInputting){
          this.isInputting = false;
          this.input = this.text;
        }
      }
    },
    directives: {
      clickOutside: vClickOutside.directive
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }


</style>
