<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Image</h2>
    </div>
      <image-rendering-setter :el="el" :group-id="groupId"></image-rendering-setter>
      <object-fit-setter :el="el" :group-id="groupId"></object-fit-setter>
  </div>
</template>

<script>
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import SkewXPercentageSetter from "../styles/transform/SkewXPercentageSetter";
 import SkewYPercentageSetter from "../styles/transform/SkewYPercentageSetter";
 import TransformFunctionSetter from "../styles/transform/TransformFunctionSetter";
 import RotateXPercentageSetter from "../styles/transform/RotateXPercentageSetter";
 import RotateYPercentageSetter from "../styles/transform/RotateYPercentageSetter";
 import RotateZPercentageSetter from "../styles/transform/RotateZPercentageSetter";
 import ImageRenderingSetter from "../styles/image/ImageRenderingSetter";
import ObjectFitSetter from "../styles/image/ObjectFitSetter";

export default {
  name: 'ImageCategory',
  components: {
    ObjectFitSetter,
    ImageRenderingSetter,
    TransformFunctionSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
