import MissionControl from "../../../models/MissionControl";
import taskMapper from "sharerui/src/action/mappers/TaskMapper"
import interactionDescriptorMapper from "./InteractionDescriptorMapper";
import taskCommentMapper from "sharerui/src/action/mappers/TaskCommentMapper";
import userMapper from "sharerui/src/action/mappers/UserMapper";

class MissionControlMapper{

    map(data){
        const tasks = data.tasks.map(taskMapper.map);

        const mappedComments = {};

        for(let key of Object.keys(data.taskComments)){
            mappedComments[key] = data.taskComments[key].map(taskCommentMapper.map);

        }

        let m = new MissionControl(tasks, mappedComments, data.interactions.map(interactionDescriptorMapper.map), userMapper.map(data.user))
        window.m = m;
        return m;
    }

}

let missionControlMapper = new MissionControlMapper();
export default missionControlMapper;
