<template>

  <div class="flex">
    <h2>
      Color
    </h2>

    <div>
      <div>
        f
      </div>

    </div>

    <!--<chrome
          v-show="false"
          v-model="selectedValue" />-->
  </div>

</template>

<script>

import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../mixin/StyleSetter";
import Chrome from 'vue-color/src/components/Chrome.vue';


export default {
  name: 'ColorSetter',
  components: {Chrome},
  mixins: [StyleSetter],
  data(){
    return {
      selectedValue: 'inherit',
      propertyName: 'color'
    }
  },

  methods: {
    mapSelectedValueToStyle(v){
      return  v.hex8
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
