<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Spacing</h2>
    </div>
      <h3>Padding</h3>
        <padding-all-setter :el="el" :group-id="groupId"></padding-all-setter>
        <padding-top-setter :el="el" :group-id="groupId"></padding-top-setter>
        <padding-right-setter :el="el" :group-id="groupId"></padding-right-setter>
        <padding-bottom-setter :el="el" :group-id="groupId"></padding-bottom-setter>
        <padding-left-setter :el="el" :group-id="groupId"></padding-left-setter>

      <h3>Margin</h3>
        <margin-all-setter :el="el" :group-id="groupId"></margin-all-setter>
        <margin-top-setter :el="el" :group-id="groupId"></margin-top-setter>
        <margin-right-setter :el="el" :group-id="groupId"></margin-right-setter>
        <margin-bottom-setter :el="el" :group-id="groupId"></margin-bottom-setter>
        <margin-left-setter :el="el" :group-id="groupId"></margin-left-setter>

  </div>
</template>

<script>
 import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import SkewXPercentageSetter from "../styles/transform/SkewXPercentageSetter";
 import SkewYPercentageSetter from "../styles/transform/SkewYPercentageSetter";
 import TransformFunctionSetter from "../styles/transform/TransformFunctionSetter";
 import RotateXPercentageSetter from "../styles/transform/RotateXPercentageSetter";
 import RotateYPercentageSetter from "../styles/transform/RotateYPercentageSetter";
 import RotateZPercentageSetter from "../styles/transform/RotateZPercentageSetter";
 import PaddingTopSetter from "../styles/spacing/PaddingTopSetter";
 import PaddingRightSetter from "../styles/spacing/PaddingRightSetter";
 import PaddingBottomSetter from "../styles/spacing/PaddingBottomSetter";
 import MarginTopSetter from "../styles/spacing/MarginTopSetter";
 import MarginRightSetter from "../styles/spacing/MarginRightSetter";
 import MarginBottomSetter from "../styles/spacing/MarginBottomSetter";
 import PaddingLeftSetter from "../styles/spacing/PaddingLeftSetter";
 import MarginLeftSetter from "../styles/spacing/MarginLeftSetter";
 import MarginAllSetter from "../styles/spacing/MarginAllSetter";
 import PaddingAllSetter from "../styles/spacing/PaddingAllSetter";

export default {
  name: 'SpacingCategory',
  components: {
    PaddingAllSetter,
    MarginAllSetter,
    MarginLeftSetter,
    PaddingLeftSetter,
    MarginBottomSetter,
    MarginRightSetter,
    MarginTopSetter,
    PaddingBottomSetter,
    PaddingRightSetter,
    PaddingTopSetter,
    RotateZPercentageSetter,
    RotateYPercentageSetter,
    RotateXPercentageSetter,
    TransformFunctionSetter,
    SkewYPercentageSetter,
    SkewXPercentageSetter,
    LetterSpacingSetter,
    TextTransformSetter,
    ColumnCountSetter,
    FontFamilySetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
