export default class InteractionRecorded  {

    static name = 'InteractionRecorded';

    selector;
    groupId;
    propertyName;
    type;
    oldPropertyValue;
    newPropertyValue;


    constructor(selector, groupId, propertyName, type, oldPropertyValue, newPropertyValue) {
        this.selector = selector;
        this.groupId = groupId;
        this.propertyName = propertyName;
        this.type = type;
        this.oldPropertyValue = oldPropertyValue;
        this.newPropertyValue = newPropertyValue;
    }
}