import axios from 'axios'

class DeselectElementHandler {

    /**
     *
     * @param command {DeselectElement}
     * @returns {Promise<void>}
     */
    async handle(command) {

        await axios.request({
            method: 'delete',
            url:  window._collabRocket.apiUrl + '/api/view/select',
            data:  {
                'task_uuid': command.taskUuid,
                'view_uuid': command.viewUuid,
                'selector': command.selector
            },
            withCredentials: true
        }


        )


    }
}

let deselectElementHandler = new DeselectElementHandler();
export default deselectElementHandler;