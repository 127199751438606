import unique from 'unique-selector';


class SelectorGenerator{

    get(el){
        return unique(el);
    }
}

let selectorGenerator = new SelectorGenerator();
export default selectorGenerator;