import Quantity from "../models/Quantity";

class CssParamParser{

    parseArgumentWithUnit(position, style){
        if(!style){
            return null;
        }

        let splits = style.split(" ");

        if(splits.length < position){
            return null;
        }

        let split = splits[position].trim();

        return this.parseSingleArgumentWithUnit(split)
    }

    parseSingleArgumentWithUnit(style){
        if(!style){
            return null;
        }

        let split = style + '';

        let number = window.parseFloat(split);

        let parsedNumber;
        let unit;
        if(!window.isNaN(number)){
            parsedNumber = number;
        }else{
            return null;
        }

        unit = split.replace(number, '');

        return new Quantity(parsedNumber, unit);
    }

    parseArgument(position, style, sep = " "){
        if(!style){
            return null;
        }

        let splits = style.split(sep);



        if(splits.length < position){
            return null;
        }

        return splits[position]? splits[position].trim(): null;
    }

}



let cssParamParser = new CssParamParser();
export default cssParamParser;
