<template>

  <div class="text-em-sm text-gray-400 m-4 flex justify-between pb-em-1 	items-center"
       v-show="store.isTaskFocusedByIndex(taskIndex)">

        <div v-show="changes > 0">
            <slider
                    :off-label="'Before'"
                    :on-label="'After'"
                    v-model="isShowingInteractions" @input="toggleShowingOriginalPropertyValues" ></slider>
        </div>

        <div class="text-right flex-grow">
           <span v-show="changes > 0">
                            {{changes }} {{ 'change' | pluralize(changes) }}
                        </span>
          <span v-show="changes > 0 && elements > 0">
                                         &bull;
                                    </span>
          <span v-show="elements > 0">
                            {{elements}} {{ 'element' | pluralize(elements) }}
                        </span>
        </div>




  </div>
</template>

<script>

import MarginSetter from "@/components/styles/MarginSetter";
import css from "@/services/css"
import Selector from "./styles/Selector";
import store from "../store/Store";
import bus from "../bus/bus";
import StyleSetter from "../mixin/StyleSetter";
import StyleSetEvent from "../events/StyleSetEvent";
import interactionRefresher from "../router/InteractionRefresher";
import Slider from "./ui/Slider";
export default {
  name: 'TaskChangeTracker',
  components: {
      Slider
  },

  props: {
    taskIndex:  Number
  },

  data(){

    return {
      store: store,
        isShowingInteractions: false
    };
  },


  computed:{
    changes(){
      return store.getTaskStatsByIndex(this.taskIndex).changes;
    },

    elements(){
      return store.getTaskStatsByIndex(this.taskIndex).elements;
    }

  },

    methods: {
        toggleShowingOriginalPropertyValues(v){

            bus.$emit(StyleSetEvent.name);


            console.dir(store.getTaskByIndex(this.taskIndex).uuid)

            if(!v){
                store.removeActiveInteractionGroupId(store.getTaskByIndex(this.taskIndex).uuid)
                interactionRefresher.refresh()
            }else{
                store.addActiveInteractionGroupId(store.getTaskByIndex(this.taskIndex).uuid)
                interactionRefresher.refresh()
            }
        }

    },

    mounted(){

        this.$watch("store.state.activeInteractionGroupIds", function(v){
            this.isShowingInteractions = !!store.state.activeInteractionGroupIds[store.getTaskByIndex(this.taskIndex).uuid]
        });


    }
}
</script>


<style lang="scss" scoped>



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
