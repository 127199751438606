<template>
    <div>
        Text Shadow Horizontal Position
        <slider-units-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :max="100"
                :min="-100"
                v-model="selectedValue"></slider-units-picker>
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";
import textShadowResolver from "../../../services/propertyresolver/TextShadowResolver";

export default {
    name: 'TextShadowHorizontalSetter',
    components: {SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
            resolver: textShadowResolver,
            itemId: 0,
        }
    },

    methods: {

    },

    async mounted() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
