export default class MissionControl{


    /**
     * @type {Array.<Task>} tasks
     */
    _tasks;
    
    _taskComments;

    _interactions;

    user;

    /**
     *
     * @param {Array.<Task>} tasks
     * @param comments
     * @param interactions
     * @param user
     */
    constructor(tasks, comments, interactions, user) {
        this._tasks = tasks;
        this._taskComments = comments;
        this._interactions = interactions;
        this.user = user;
    }

    get tasks() {
        return this._tasks;
    }
    get taskComments() {
        return this._taskComments;
    }


    get interactions() {
        return this._interactions;
    }
}
