import setPropertyHandler from "../../action/handler/command/SetPropertyHandler";
import selectElementHandler from "../../action/handler/command/SelectElementHandler";

export default class SelectElementAction{

    /**
     * @type {SelectElement}
     */
    _selectElement;

    constructor(selectElement) {
        this._selectElement = selectElement;
    }

    async run() {
        await selectElementHandler.handle(this._selectElement)
    }
}

