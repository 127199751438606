import setPropertyHandler from "../../action/handler/command/SetPropertyHandler";

export default class SetPropertyAction{

    /**
     * @type {SetProperty}
     */
    _setProperty;

    constructor(setProperty) {
        this._setProperty = setProperty;
    }

    async run() {
        await setPropertyHandler.handle(this._setProperty)
    }
}
