<template>

    <div class="w-full" :class="{'opacity-60': unused}">
      <div class="flex justify-between">

        <div >
          {{ label }}
        </div>

          <div class="relative w-full">
              <slot name="subbar">

              </slot>
          </div>


        <div class="flex justify-center text-center items-center   text-em-xs">
          <div class="w-6" @click="$emit('reset')">
            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
          </div>
          <div class="w-6" @click="$emit('redo')">
            <font-awesome-icon icon="redo"></font-awesome-icon>
          </div>
          <div class="w-6" @click="$emit('undo')">
            <font-awesome-icon icon="undo"></font-awesome-icon>
          </div>
          <slot name="actions" :input="input"></slot>
        </div>

      </div>

      <div
            class="flex justify-center">
          <div class="w-full">

              <div v-show="input && !isInputting">
                  <slot name="input" :input="input"></slot>

              </div>
              <div v-show="isInputting">
                  <slot  name="inputting"></slot>
              </div>
          </div>
          <div>
            <slot name="input-actions"></slot>
          </div>
      </div>
    </div>
</template>

<script>

import vClickOutside from 'v-click-outside'
import bus from "../../bus/bus";
import StyleSetEvent from "../../events/StyleSetEvent";
import css from "../../services/css";

export default {
  name: 'Picker',
  components: {
  },

  props: {
    isInputting: Boolean,
    input: {},
    label: {},
      unused: {
          type: Boolean,
          default: false
      },
  },

  data(){
    return {
        filteredInput: this.input
    };
  },

    methods:{


    },

    watch: {


     /* input(v){
          this.filteredInput = this.input;
      }*/
    },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
