import axios from 'axios';
import swimlaneLabelMapper from "../mapper/SwimlaneLabelMapper";

class SearchSwimlanesHandler{

    /**
     *
     * @param {SearchSwimlanes} query
     * @returns {Promise<User[]>}
     */
    async handle(query){
        var model = await axios.request({
            url: window._collabRocket.apiUrl + '/api/project/swimlanes/term',
            method: 'get',
            params: {
                term: query.term,
                site_uuid: query.siteUuid
            },
                withCredentials: true
        });

        return  model.data.swimlanes.map(swimlaneLabelMapper.map)
    }


}

let searchSwimlanesHandler = new SearchSwimlanesHandler();
export default searchSwimlanesHandler;
