import PropertyInteractionRecord from "./PropertyInteractionRecord";
import CssPropertyValue from "./CssPropertyValue";
import SelectorInteractionRecord from "./SelectorInteractionRecord";
import GroupOptionSet from "../events/GroupOptionSet";
import Prioritiser from "../models/Prioritiser";
import InteractionRecorded from "../events/InteractionRecorded";
import InteractionType from "./InteractionType";

export class InteractionRecorder{

    /**
     *
     * @type {{string: SelectorInteractionRecord}}
     */
    selectorInteractionRecords = {};

    _groupOptionSetCallbacks = [];

    _prioritiser = new Prioritiser();

    _onRecordCallbacks = [];

    onGroupOptionSet(fn){
        this._groupOptionSetCallbacks.push(fn);
    }

    onRecord(fn){
        this._onRecordCallbacks.push(fn);
    }

    getSelectorsWithGroupOptionForGroupId(groupId, optionName){

        return Object.keys(this.selectorInteractionRecords)
            .filter(s => this.selectorInteractionRecords[s].hasGroupOptionForOption(groupId, optionName));
    }

    select(selector, groupId){
        this._createSelectorInteractionRecords(selector);
        this.selectorInteractionRecords[selector].select(groupId)
    }

    setGroupOption(selector, groupId, optionName, optionValue){
        this._createSelectorInteractionRecords(selector);
        this.selectorInteractionRecords[selector].setGroupOption(groupId, optionName, optionValue)

        for(let fn of this._groupOptionSetCallbacks){
            fn(new GroupOptionSet(selector, groupId, optionName, optionValue));
        }
    }

    getGroupOption(selector, groupId, optionName){
        this._createSelectorInteractionRecords(selector);
        return this.selectorInteractionRecords[selector].getGroupOption(groupId, optionName)
    }

    toggle(selector, groupId){
        if(!this.selectorInteractionRecords[selector]) {
            this.selectorInteractionRecords[selector] = new SelectorInteractionRecord(selector);
            this.selectorInteractionRecords[selector].select(groupId)
        }else if(this.selectorInteractionRecords[selector].hasInteractionForGroup(groupId)){
            this.selectorInteractionRecords[selector].deselect(groupId)
        }else{
            this.selectorInteractionRecords[selector].select(groupId)
        }
    }

    _createSelectorInteractionRecords(selector){
        if(!this.selectorInteractionRecords[selector]) {
            this.selectorInteractionRecords[selector] = new SelectorInteractionRecord(selector);
        }
    }


    deselect(selector, groupId){
        if(this.selectorInteractionRecords[selector]) {
            this.selectorInteractionRecords[selector].deselect(groupId)
        }
    }

    record(selector, groupId, propertyName, type, oldPropertyValue, newPropertyValue){
        this._createSelectorInteractionRecords(selector);
        this.selectorInteractionRecords[selector].record(propertyName, groupId, type, oldPropertyValue, newPropertyValue);

        for(let fn of this._onRecordCallbacks){
            fn(new InteractionRecorded(selector, groupId, propertyName, type, oldPropertyValue, newPropertyValue));
        }
    }

    undoPropertyValue(selector, groupId, propertyName){
        if(this.selectorInteractionRecords[selector]){
            this.selectorInteractionRecords[selector].undoPropertyValue(groupId, propertyName)
        }
    }

    redoPropertyValue(selector, groupId, propertyName){
        if(this.selectorInteractionRecords[selector]){
            this.selectorInteractionRecords[selector].redoPropertyValue(groupId, propertyName)
        }
    }

    //get interactionRecords(){
    //    return this.interactionRecords;
    //}

    getSelectorPropertyValues(selector, priorityFunction, activePredicate, type){ //priorityFunction activePredicaete
        return this.selectorInteractionRecords[selector]
            ? this.selectorInteractionRecords[selector].getPropertyValues(priorityFunction, activePredicate, type)
            : null;
    }

    //hasPropertyValueForGroup(groupId){
    getSelectorsUsedByGroup(groupId){
        let selectors = [];
        for (let cssSelectorRecord of Object.values(this.selectorInteractionRecords)){
            if(cssSelectorRecord.hasInteractionForGroup(groupId)){
                selectors.push(cssSelectorRecord.selector);
            }
        }

        return selectors;
    }

    hasInteractionForSelector(selector, groupId){
        if(this.selectorInteractionRecords[selector]){
            return this.selectorInteractionRecords[selector].hasInteractionForGroup(groupId);
        }else{
            return false;
        }

    }


    getNumberOfGroupSelectors(groupId){
        let num = 0;
        for (let selectorInteractionRecord of Object.values(this.selectorInteractionRecords)) {
            if(selectorInteractionRecord.hasInteractionForGroup(groupId)){
                num++;
            }
        }

        return num;
    }

    getPropertyValue(selector, groupId, propertyName){
        if(this.selectorInteractionRecords[selector]
            && this.selectorInteractionRecords[selector].propertyRecords[propertyName]
        ){
            return this.selectorInteractionRecords[selector].propertyRecords[propertyName].getPropertyValueForGroup(groupId);
        }else{
            return undefined;
        }
    }

    getSelectorGroupPropertyValues(selector, groupId, type){
        if(this.selectorInteractionRecords[selector]){
            return this.selectorInteractionRecords[selector].getPropertyValuesForGroupId(groupId, type);
        }else{
            return {}
        }
    }

    getNumberOfGroupPropertyValues(groupId){
        let num = 0;
        for (let selectorInteractionRecord of Object.values(this.selectorInteractionRecords)) {
                if(selectorInteractionRecord.hasPropertyValueForGroup(groupId)){
                    num++;
                }
        }

        return num;
    }

    getSelectorsUsedByGroupByPropertyName(groupId, propertyName) {
        let selectors = [];
        for (let cssSelectorRecord of Object.values(this.selectorInteractionRecords)){
            if(cssSelectorRecord.hasInteractionForGroupByPropertyName(groupId, propertyName)){
                selectors.push(cssSelectorRecord.selector);
            }
        }

        return selectors;
    }

    hasInteractionForGroupByPropertyName(selector, groupId, propertyName){
        return this.selectorInteractionRecords[selector] && this.selectorInteractionRecords[selector].hasInteractionForGroupByPropertyName(groupId, propertyName)
    }


    visitProperties(priorityFunction, activePredicate, type, fn){
        for(let [selector, selectorInteractionRecord] of Object.entries(this.selectorInteractionRecords)){
            if(!selector){
                return;
            }

            let props = selectorInteractionRecord.getPropertyValues(priorityFunction, activePredicate, type);

            for(let [propertyName, propertyValue] of Object.entries(props)){
                fn(selector, propertyName, propertyValue)
            }
        }
    }

    visitActiveGroupProperties(priorityFunction, activeGroupIds, type, fn){

        let filteredSelectorInteractionRecords =
            Object.fromEntries(
                Object.entries(this.selectorInteractionRecords)
                    .filter(([_, s]) => s.hasInteractionForAnyGroup(activeGroupIds)));

        for(let [selector, selectorInteractionRecord] of Object.entries(filteredSelectorInteractionRecords)){
            if(!selector){
                return;
            }

            let props = selectorInteractionRecord.getPropertyValues(priorityFunction, null, type);

            for(let [propertyName, propertyValue] of Object.entries(props)){
                fn(selector, propertyName, propertyValue)
            }
        }
    }

}


let interactionRecorder = new InteractionRecorder();
window.interactionRecorder = interactionRecorder;
export default interactionRecorder;