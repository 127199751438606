import css from "@/services/css"
import store from "../../store/Store";

class TaskCommentDeletedMessageHandler{


    handle(uuid){
        store.deleteTaskComment(uuid);
    }
}

let taskCommentDeletedMessageHandler = new TaskCommentDeletedMessageHandler();
export default taskCommentDeletedMessageHandler;
