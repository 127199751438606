<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Transform</h2>
    </div>
      <transform-function-setter :el="el" :group-id="groupId"></transform-function-setter>
      <skew-x-percentage-setter :el="el" :group-id="groupId"></skew-x-percentage-setter>
      <skew-y-percentage-setter :el="el" :group-id="groupId"></skew-y-percentage-setter>
      <rotate-x-percentage-setter :el="el" :group-id="groupId"></rotate-x-percentage-setter>
      <rotate-y-percentage-setter :el="el" :group-id="groupId"></rotate-y-percentage-setter>
      <rotate-z-percentage-setter :el="el" :group-id="groupId"></rotate-z-percentage-setter>
  </div>
</template>

<script>
 import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import SkewXPercentageSetter from "../styles/transform/SkewXPercentageSetter";
 import SkewYPercentageSetter from "../styles/transform/SkewYPercentageSetter";
 import TransformFunctionSetter from "../styles/transform/TransformFunctionSetter";
 import RotateXPercentageSetter from "../styles/transform/RotateXPercentageSetter";
 import RotateYPercentageSetter from "../styles/transform/RotateYPercentageSetter";
 import RotateZPercentageSetter from "../styles/transform/RotateZPercentageSetter";

export default {
  name: 'TransformCategory',
  components: {
    RotateZPercentageSetter,
    RotateYPercentageSetter,
    RotateXPercentageSetter,
    TransformFunctionSetter,
    SkewYPercentageSetter,
    SkewXPercentageSetter,
    LetterSpacingSetter,
    TextTransformSetter,
    ColumnCountSetter,
    FontFamilySetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
