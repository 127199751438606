import css from "../services/css";
import bus from "../bus/bus";
import GroupActivated from "../events/GroupActivated";
import GroupDeactivated from "../events/GroupDeactivated";
import InteractionsRefreshed from "../events/InteractionsRefreshed";
import store from "../store/Store";

class InteractionRefresher{

    refresh(){
        css.refreshAllCss((t) => t.uuid, (id) => !!store.state.activeInteractionGroupIds[id])

        bus.$emit(InteractionsRefreshed.name, new InteractionsRefreshed());

        for(let task of store.state.tasks){
            if(store.state.activeInteractionGroupIds[task.uuid]){
                bus.$emit(GroupActivated.name, new GroupActivated(task.uuid));
            }else{
                bus.$emit(GroupDeactivated.name, new GroupDeactivated(task.uuid));
            }
        }
    }
}

let interactionRefresher = new InteractionRefresher();
export default interactionRefresher;


