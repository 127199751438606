import _ from 'lodash'
import Prioritiser from "../models/Prioritiser";
//ground equity valuation on risk free yield
export default class PropertyInteractionRecord{

    _selector;
    propertyName;
    type;
    _originalPropertyValue;
    _propertyValues;
    _propertyValueGroupIndex = {};

    _sorter = new Prioritiser();

    constructor(selector, propertyName, type, oldPropertyValue) {
        this._selector = selector;
        this.propertyName = propertyName;
        this._propertyValues = {};
        this.type = type;
        this._originalPropertyValue = oldPropertyValue;
    }

    deselect(groupId){
        if(this._propertyValues[groupId]){
            delete  this._propertyValues[groupId];
            delete  this._propertyValueGroupIndex[groupId];
        }
    }

    setPropertyValue(groupId, value){
        //this._propertyValues.push(value);
        //for now just record original and latest value+

        if(!this._propertyValues[groupId]){
            this._propertyValues[groupId] = [];
            this._propertyValueGroupIndex[groupId] = -1;
        }

        this._propertyValues[groupId] = this._propertyValues[groupId].slice(0, this._propertyValueGroupIndex[groupId] + 1);
        //this._propertyValues[groupId][0] = value;
        this._propertyValueGroupIndex[groupId]++;
        this._propertyValues[groupId].push(value);
    }

    undoPropertyValue(groupId){
        if(!this._propertyValues[groupId] || !(this._propertyValueGroupIndex[groupId] > -1)){
            return;
        }

        this._propertyValueGroupIndex[groupId]--;
    }

    redoPropertyValue(groupId){
        if(!this._propertyValues[groupId] || this._propertyValueGroupIndex[groupId] === this._propertyValues[groupId].length - 1){
            return;
        }

        this._propertyValueGroupIndex[groupId]++;
    }


    hasPropertyValueForGroup(groupId){
        return !!this._propertyValues[groupId] && this._propertyValues[groupId].length && this._propertyValues[groupId][0] != null;
    }


    resetPropertyValue(){
        this._propertyValues = []
    }

    get originalPropertyValue(){
        return this._originalPropertyValue;
    }

    getPropertyValueForGroup(groupId){
        if(this._propertyValueGroupIndex[groupId] === -1){
            return this._originalPropertyValue;
        }else if(this._propertyValues[groupId]){
            return this._propertyValues[groupId][this._propertyValueGroupIndex[groupId]];
        }else{
            return null;
        }
    }

    /**
     *
     * @param priorityFunction
     * @param activePredicate
     * @returns {String}
     */
    getPropertyValue(priorityFunction, activePredicate){

        return this._sorter.sortActiveByPredicate(
            priorityFunction,
            activePredicate,
            this._propertyValues,
            (propertyValues, groupId) => this.getPropertyValueForGroup(groupId),
            () => this._originalPropertyValue);
    }

    /*get propertyValue(){
        return this._propertyValues[this._propertyValues.length - 1];
    }*/
}