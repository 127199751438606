<template>
    <div>
        <image-picker
                label="Background Image"
                v-model="selectedValue"
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                :property-name="propertyName"
        />


    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import axios from 'axios'
import modificationDao from "../../daos/ModificationDao";
import _ from 'lodash'
import StyleSetter from "../../mixin/StyleSetter";
import Chrome from 'vue-color/src/components/Chrome.vue';
import tinycolor from 'tinycolor2';
import ColorPicker from "./ColorPicker";
import TextPicker from "./TextPicker";
import ImagePicker from "./ImagePicker";

export default {
    name: 'BackgroundImageSetter',
    components: {ImagePicker, TextPicker, ColorPicker, Chrome},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'background-image'
        }
    },

  methods: {
    mapSelectedValueToStyle(v){
      return  'url(' + v +  ')'; //v.hex8
    },

    mapStyleToSelectedValue(v){
      return v;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
