<template>
  <div id="collab-rocket-app" ref="app" @click="onClick"
    v-show="state.isLoggedIn">
    <div id="app-cover"
         v-show="state.isShowingMissionControl"
    >
    </div>
    <div id="app-content">
      <mission-control
              v-show="state.isShowingMissionControl"
      ></mission-control>

      <div id="visibility-button"
           class="p-3 cursor-pointer"
        @click="toggleMissionControlVisibility"
      >
        <img :src="logo">
      </div>
    </div>
  </div>
</template>

<script>

import MissionControl from "./components/MissionControl";
import store from "./store/Store";
export default {
  name: 'App',
  components: {
    MissionControl
  },

  mounted(){
    store.state.$app = this.$refs.app;
    store.state.isShowingMissionControl = window.localStorage.getItem('__collabRocket.isShowingMissionControl')  === 'true'
  },

  computed: {
    logo(){
      return (window._collabRocket? window._collabRocket.sidebarUrl: '') + '/img/icons/logopurple.svg';
    }
  },

  methods: {
    toggleMissionControlVisibility(){
      store.state.isShowingMissionControl = !store.state.isShowingMissionControl;
      window.localStorage.setItem('__collabRocket.isShowingMissionControl', store.state.isShowingMissionControl)
    },

    onClick(e){
      let comesFromContent = false;

      let el = e.target;
      while(el !== this.$refs.app){
        if(el.getAttribute('data-type') === 'taskForm'
            || el.getAttribute('data-type') === 'inspector'){
          comesFromContent = true;
          break;
        }

        el = el.parentElement;
      }

      if(!comesFromContent){
        console.log(e)
        store.defocusTask()
      }
    }
  },

  data(){

    return {
      state: store.state
    }
  },

  //set NODE_OPTIONS=--openssl-legacy-provider

}
</script>


<style scsoped lang="scss">

@import 'assets/styles/quill.snow';
@import 'assets/styles/quill.mention.min';
@import 'assets/styles/tailwind.min';



  #collab-rocket-app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #2c3e50;
    position: fixed;
    right: 0px;
    z-index: 2147483646;
    width: 350px;
    top:0px;
    bottom: 0px;
    line-height: 1.5;

  }



  #collab-rocket-app{
    font-size: 16px;
  }

#collab-rocket-app *{
  .font-size: 1em;
}


#app-content, #app-cover{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;


  }

  #app-content{
    overflow: scroll;
    padding-bottom: 75px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  #app-content::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  #app-content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  #app-cover{
    backdrop-filter: blur(40px);
    background: #ffffff88;
    box-shadow: 0 0 5px #d1d1d1;
  }

  #visibility-button {
    position: fixed;
    bottom: 0px;
    right: 0px;
  }

  #visibility-button img{
    height: 50px;
    opacity: 0.5;
    transition-duration: 500ms;
    filter: grayscale(40%);
  }

  #visibility-button img:hover{
    filter: grayscale(0%);
    opacity: 1;
    transform: rotate(-10deg);
  }

  #nav {
    padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

  &.router-link-exact-active {
     color: #42b983;
   }
  }
  }


</style>