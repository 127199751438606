
import SwimlaneLabel from "../../../models/SwimlaneLabel";

class SwimlaneLabelMapper{
    map(data){
        return new SwimlaneLabel(data.uuid, data.name)
    }
}

let swimlaneLabelMapper = new SwimlaneLabelMapper();
export default swimlaneLabelMapper;
