import axios from 'axios'

class SelectElementHandler {

    /**
     *
     * @param command {SelectElement}
     * @returns {Promise<void>}
     */
    async handle(command) {

        console.log('s started')
        var model = await axios.post(window._collabRocket.apiUrl + '/api/view/select',
            {
                'task_uuid': command.taskUuid,
                'view_uuid': command.viewUuid,
                'selector': command.selector
            },

            {
                withCredentials: true
            }
        )
    }
}

let selectElementHandler = new SelectElementHandler();
export default selectElementHandler;