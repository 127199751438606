export default class EditableInputted  {

    static name = 'EditableInputted';

    selector;
    groupId;
    input;

    constructor(selector, groupId, input) {
        this.selector = selector;
        this.groupId = groupId;
        this.input = input;
    }
}