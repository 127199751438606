<template>

  <div>
    <text-picker :label="label"
                 @input="onInput"
                 :value="value"
                 @reset="$emit('reset')"
                 @undo="$emit('undo')"
                 @redo="$emit('redo')">

      <template v-slot:input="params">
        <img
                v-show="!loading && !errorLoading"
                @load="onLoad" @error="onError" :src="convertPropertyValueToSrc(params.input)">


        <div v-show="loading">Loading...</div>
        <div v-show="!loading" class="w-60 truncate text-sm text-gray-400" :title="params.input">
          {{ params.input }}
        </div>
        <div v-show="errorLoading" class="text-xs text-yellow-700	">Can't load image</div>



      </template>
    </text-picker>
  </div>
</template>

<script>
import Picker from "./Picker";
import TextPicker from "./TextPicker";
export default {
  name: 'ImagePicker',
  components: {
    TextPicker,
    Picker
  },

  props: {
    label: String,
    value: String,
  },

  data(){

    return {
      errorLoading: false,
      loading: false
    };
  },

  methods: {
    onInput(v){
      this.loading = true;
      this.errorLoading = false;
      this.$emit('input', v)
    },

    onLoad(){
      this.errorLoading = false;
      this.loading = false;
    },

    convertPropertyValueToSrc(v){
      if(!v){
        return v;
      }

      if(v.indexOf('url("') === 0 || v.indexOf('url(\'') === 0) {
        return v.substring(0, v.length - 2).substring(5)
      }
      else if(v.indexOf('url(') === 0 ){
        return v.substring(0, v.length -1).substring(4)
      }else{
        return v
      }

    },

    onError(v){
      this.loading = false;
      this.errorLoading = true;


    }

  },

  mounted(){
  }
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
