<template>
  <div>

    <slider
            :off-label="'Before All'"
            :on-label="'After All'"
            v-model="value" @input="refreshAll"></slider>
  </div>
</template>

<script>

import MarginSetter from "@/components/styles/MarginSetter";
import css from "@/services/css"
import Selector from "./styles/Selector";
import store from "../store/Store";
import Slider from "./ui/Slider";
import interactionRefresher from "../router/InteractionRefresher";
export default {
  name: 'InteractionRefresher',
  components: {
    Slider
  },

  data(){

    return {
      value: false,
      state: store.state


    };
  },

  methods: {
    refreshAll(v){
      if(v){
        store.addAllGroupsToActiveInteractionGroupIds()
        interactionRefresher.refresh()
      }else{
        store.removeAllGroupsToActiveInteractionGroupIds();
        interactionRefresher.refresh()
      }
    },
  },

  mounted(){


    this.$watch("state.activeInteractionGroupIds", function(v){
      console.dir(store.areAllGroupsActive())
      this.value = store.areAllGroupsActive();
    });


  }
}
</script>


<style lang="scss" scoped>



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
