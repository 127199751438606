<template>

    <div class=""  :class="{'shake' : animated}">
        <slot name="default"></slot>
    </div>
</template>

<script>

    export default {
        name: 'Shaker'
    ,
        data() {
        return {
            animated: false
        }
    },
    methods: {
        shake() {
            const self = this
            self.animated = true
            setTimeout(() => {
                self.animated = false
            }, 1000)
        }

    }
    }
</script>


<style lang="scss">

    .shaker {
        padding: 1rem 3rem;
        background: #a0f0c0;
        font-size: 1rem;
        border-radius: 3px;
        box-shadow: 3px 3px #555;
        &:hover {
            background: #f0a0d0;
            box-shadow: 3px 3px #333;
        }
        &:focus {
            outline: none;
        }
    }

    .shake {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
    }
    @keyframes shake {
        10%, 90% {
            transform: translate3d(-1px, 0, 0);
        }
        20%, 80% {
            transform: translate3d(2px, 0, 0);
        }
        30%, 50%, 70% {
            transform: translate3d(-4px, 0, 0);
        }
        40%, 60% {
            transform: translate3d(4px, 0, 0);
        }
    }
</style>
