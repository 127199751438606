import _ from "lodash";

export default class Prioritiser{

    /**
     *
     * @param priorityFunction {function(string): number|string}
     * @param activePredicate {function(string): boolean}
     * @param keyedValues {{string: object}}
     * @param valueMapper {function(object, string): string}
     * @param defaultValue {function(): string}
     * @returns
     */
    sortActiveByPredicate(priorityFunction, activePredicate, keyedValues, valueMapper, defaultValue){

        priorityFunction = priorityFunction || ((e) => 0);
        activePredicate = activePredicate || ((e) => true);

        let activeValues = {};
        for(let [groupId, _] of Object.entries(keyedValues)){

            if(activePredicate(groupId)){
                activeValues[groupId] = valueMapper(keyedValues, groupId); //_.last(propertyValues);
            }
        }

        if(_.isEmpty(activeValues)){
            return defaultValue();
        }


        let activeValueKeys = Object.keys(activeValues);
        if(activeValueKeys.length === 1){
            return activeValues[activeValueKeys[0]]
        }

        let sorted = activeValueKeys.sort((a, b) => priorityFunction(a) - priorityFunction(b))
        return activeValues[_.last(sorted)];


    }
}