import css from "@/services/css"
import InteractionType from "@/services/InteractionType"
import store from "@/store/Store";


class InteractionUpdateMessageHandler{

    handle(interaction){
        if(interaction.type === InteractionType.STYLE){
            interactionRecorder.record(interaction.selector, interaction.taskUuid, interaction.property, InteractionType.STYLE,
                css.get(document.querySelector(interaction.selector), interaction.property), interaction.value);
        }else if(interaction.isDeselect){
            interactionRecorder.deselect(interaction.selector, interaction.taskUuid);
        }else{
            interactionRecorder.select(interaction.selector, interaction.taskUuid);

        }

        store.state.taskStats[interaction.taskUuid].elements = interactionRecorder.getNumberOfGroupSelectors(interaction.taskUuid)
        store.state.taskStats[interaction.taskUuid].changes = interactionRecorder.getNumberOfGroupPropertyValues(interaction.taskUuid)

        css.refreshAllCss((e) => 0, (groupId) => store.isGroupActive(groupId));
    }
}

let interactionUpdateMessageHandler = new InteractionUpdateMessageHandler();
export default interactionUpdateMessageHandler;
