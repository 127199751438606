import { render, staticRenderFns } from "./TopSetter.vue?vue&type=template&id=47f21786&scoped=true&"
import script from "./TopSetter.vue?vue&type=script&lang=js&"
export * from "./TopSetter.vue?vue&type=script&lang=js&"
import style0 from "./TopSetter.vue?vue&type=style&index=0&id=47f21786&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f21786",
  null
  
)

export default component.exports