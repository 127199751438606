export default class StyleSetEvent {

    static name = 'StyleSet';
    selector;
    propertyName;
    propertyValue;


    constructor(selector, propertyName, propertyValue) {
        this.selector = selector;
        this.propertyName = propertyName;
        this.propertyValue = propertyValue;
    }
}