
import InteractionDescriptor from "../../../models/InteractionDescriptor";

class InteractionDescriptorMapper{
    map(data){
        return new InteractionDescriptor(data.uuid, data.taskUuid, data.selector, data.type, data.property, data.value)
    }
}

let interactionDescriptorMapper = new InteractionDescriptorMapper();
export default interactionDescriptorMapper;
