export default class Quantity {

    amount;
    unit;

    constructor(amount, unit) {
        this.amount = amount;
        this.unit = unit;
    }

    get formatted(){
        return this.amount + this.unit;
    }

}