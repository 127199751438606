<template>
  <div>
      <div
              class="p-em-4 text-center italic text-gray-500 pt-em-6"
              v-show="!state.tasks.length && isDataLoaded">
          Hold down the Control (Windows) / Command (Mac) key and click on any element to create a task 😊</div>

    <selector></selector>

      <div v-if="state.tasks.length"  v-show="state.viewMode !== 'inspect'">

          <div class="before-all flex items-center justify-between">
              <interaction-refresher></interaction-refresher>

              <burger>
                  <burger-action @click="copyCssToClipboard">
                      Copy Active CSS
                  </burger-action>
              </burger>
          </div>

          <textarea ref="cssSnippet" class="h-1	w-1 opacity-0"></textarea>

              <task-form
                      v-for="(_, index) in state.tasks.length"
                      :task-index="index"
                      v-show="!state.tasks[index].isArchived"
                      @onInspect="state.viewMode = 'inspect'"
                      :key="state.tasks[index].uuid"
              >
              </task-form>

      </div>

        <inspector
                v-show="state.viewMode === 'inspect'">
        </inspector>
      <!--<margin-setter :el.sync="el"></margin-setter>-->
    </div>
</template>

<script>

    import MarginSetter from "@/components/styles/MarginSetter";
    import css from "@/services/css"
    import Selector from "./styles/Selector";
    import store from "../store/Store";
    import TaskForm from "./TaskForm";
    import Inspector from "./Inspector";
    import interactionRecorder from "../services/InteractionRecorder";
    import bus from "../bus/bus";
    import StyleSetEvent from "../events/StyleSetEvent";
    import Slider from "./ui/Slider";
    import InteractionRefresher from "./InteractionRefresher";
    import messageListener from "../services/listeners/MessageListener";
    import missionControlDataLoader from "../services/MissionControlDataLoader";
    import elementFocusedListener from "../services/listeners/ElementFocusedListener";
    import Burger from "sharerui/src/components/controls/Burger";
    import BurgerAction from "sharerui/src/components/controls/BurgerAction";
    import taskCssFormatter from "../services/TaskCssFormatter";


    export default {
  name: 'MissionControl',
  components: {
      InteractionRefresher,
    Inspector,
    TaskForm,
    Selector,
    MarginSetter,
      Slider,
      Burger,
      BurgerAction
  },

  data(){
    return {
      state: store.state,
      el: null,
      isDataLoaded: false,
      isStarted: false
    }
  },

  methods: {
      copyCssToClipboard(){
          this.$refs.cssSnippet.value = taskCssFormatter.getForTaskIds(Object.keys(store.state.activeInteractionGroupIds));
          this.$refs.cssSnippet.focus();
          this.$refs.cssSnippet.select()
          document.execCommand('copy');
          window.getSelection().removeAllRanges();
          this.$refs.cssSnippet.blur()

      },

    createDefocusTaskHandler() {
      document.addEventListener("keydown", function (event) {
        // Number 13 is the "Enter" key on the keyboard

        if ((event.keyCode === 13 || event.key === 'Enter')
            && event.target.className.indexOf('ql-editor') === -1) {
            console.log(event)
            console.log(event.target.className)
            store.defocusTask()
        }
      });
    },


      start(){

          let that = this;
          //window.store = store;
          messageListener.startListening();
          this.createDefocusTaskHandler();
          elementFocusedListener.startListening();
          missionControlDataLoader.loadData().then(function(){
              that.isDataLoaded = true;
          });


          bus.$on(StyleSetEvent.name, function(event){
              store.state.taskStats[store.state.focusedTask.uuid].changes = interactionRecorder.getNumberOfGroupPropertyValues(store.state.focusedTask.uuid)
          });
      },

      tryStart(attempts){
          if(attempts > 10){
              return;
          }

          if(window._collabRocket){
              if(window._collabRocket.isLoggedIn){
                  this.state.isLoggedIn = true;
                  this.start();
              }
          }else{
              let that = this;
              setTimeout(function () {
                  that.tryStart(attempts + 1)
              }, 500);
          }
      },
  },


  async mounted() {
    this.tryStart(0);
  },


    watch: {
      'state.focusedTask': function (v) {

          if(v){
              let selectors = css.getSelectorsUsedByGroup(store.state.focusedTask.uuid);
              let els = [];
              for(let selector of selectors){
                  els.push(document.querySelector(selector));
              }

              store.state.selectedElements = els;
          }
      }
    }
}
</script>


<style lang="scss">

    .before-all{
        margin: 15px;
        margin-top: 15px;
        margin-bottom: -10px;
    }

/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
