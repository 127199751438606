<template>

    <div>
        <div class="letter-image w-5 h-5">
            {{ firstLetter }}
        </div>

        <MyImage
                v-show="user && false"
                class-names="w-5 h-5 rounded-full"
                :src="src" ></MyImage>
    </div>

</template>

<script>


    import MyImage from "./MyImage";

    export default {
        name: 'ProfileImage',
        components: {MyImage},
        props: {
            user: {
                type: Object
            },
        },

        computed: {
            firstLetter(){
                return this.user? this.user.name.substring(0, 1): "";
            },
            src(){
                if(!this.user){
                    return null;
                }
                return window._collabRocket.apiUrl + '/api/profile_' + this.user.uuid + '.png';
            }
        }

    }
</script>
