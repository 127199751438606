<template>

  <div  class="relative"  v-on:keyup.enter.stop="enter">


    <text-picker :label="label"
                 :is-showing-input-always="true"
                 @input="onInput"
                 @reset="$emit('reset')"
                 @undo="$emit('undo')"
                 @redo="$emit('redo')">
      <template v-slot:inputting>

        <dropdown-list-select
                :fetch="fetch"
                @select="onInput"
                placeholder="Assign task to..."
                :is-select-on-active="true"
        >

          <template v-slot:button>
            <div
                    class="flex items-center pt-1 cursor-pointer capitalize"

            >
           {{ pretty(value)  || '(none)'}}
            </div>
          </template>

          <template v-slot:item="props">
            <div
                    @click="props.select"
                    class="hover:bg-gray-200 flex items-center p-2"
                    :class="{'bg-gray-300': props.active}">

              <div class="pl-2"
                   :class="{'capitalize': isShowingPretty}"
              >
                {{ pretty(props.item) }}
              </div>
            </div>
          </template>

        </dropdown-list-select>
      </template>
    </text-picker>


  </div>
</template>

<script>

import Picker from "./Picker";
import TextPicker from "./TextPicker";
import 'vue-select/dist/vue-select.css';
import DropdownListSelect from "sharerui/src/components/ui/DropdownListSelect";



export default {
  name: 'ListPicker',
  components: {
    TextPicker,
    Picker,
    DropdownListSelect
  },

  props: {
    label: String,
    value: String,
    options: Array,
    isShowingPretty: {
      type: Boolean,
      default: true
    }
  },


  watch: {
    options(){
    }
  },

  data(){

    return {
      errorLoading: false,
      loading: false
    };
  },

  methods: {

    pretty(t){
      if(!t || !this.isShowingPretty){
        return t;
      }
      return this.isShowingPretty? t.replaceAll('-', ' '): t;
    },

    onSelect(){

    },

    fetch(term){
      return this.options.filter(option => option.toLowerCase().indexOf(term.toLowerCase()) !== -1);
    },

    enter(){
    },


    onInput(v){
      this.loading = true;
      this.errorLoading = false;
      this.$emit('input', v)

    },


  },

  mounted(){
  }
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
