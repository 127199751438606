export default class SwimlaneLabel{

    _uuid;
    _name;

    constructor(uuid, name) {
        this._uuid = uuid;
        this._name = name;
    }

    get uuid() {
        return this._uuid;
    }

    get name() {
        return this._name;
    }
}