class PropertiesResolver{

    _resolvers = {};

    registerResolver(resolver){
        this._resolvers[resolver.name] = resolver;
    }

    resolve(resolverName, selector, groupId, itemName, value){
        let resolver = this._resolvers[resolverName];

        if(!resolver){
            return [];
        }else{
            return resolver.resolve(selector, groupId, itemName, value);
        }
    }
}

let propertiesResolver = new PropertiesResolver();
export default propertiesResolver;