import SingleCssValue from "../../models/SingleCssValue";
import store from "../../store/Store";
import Resolver from "./Resolver";
import cssParamParser from "../cssParamParser";

class FilterResolver extends Resolver{

    name = 'filter';
    propertyName = "filter";



    _doResolve(selector, groupId){

        let filterFunction = this._getItemValueWithoutSpace(selector, groupId, 'filter-function', 'sepia');
        let filterParam = this._getItemValueWithoutSpace(selector, groupId, filterFunction, '50%');

        let resolved = SingleCssValue.create(
            this.name,
            filterFunction + "(" + filterParam + ")".replace(" ", "")
            );

        store.addSelectedPropertyResolverOption(selector, this.name, filterFunction);

        return [
            resolved
        ];
    }

    parseItem(selector, groupId, itemId, position, style){
        let filterFunction = this._parseFilterFunction(style);
        if(itemId === 'filter-function'){
            store.addSelectedPropertyResolverOption(selector, this.name, filterFunction);
            this._setItem(selector, groupId, 'filter-function', filterFunction);
            return filterFunction;
        }else if(itemId === filterFunction){
            let param = this._parseParam(style);
            this._setItem(selector, groupId, itemId, param);
            return param;
        }else{
            return null
        }

    }

    _parseFilterFunction(style){
        if(!style){
            return null;
        }
        style = style + '';

        return style.split('(')[0].trim();


    }

    _parseParam(style){
        if(!style){
            return null;
        }
        style = style + '';
        let split = style.split('(');
        if(split.length > 1){
            return split[1].replace(')', '').trim();
        }else{
            return null;
        }

    }

}

let filterResolver = new FilterResolver();
export default filterResolver;