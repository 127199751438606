<template>
  <div>


    <picker
            :label="label"
            :isInputting="isShowingPalette"
            :input="parsedColor"
            @reset="$emit('reset')"
            @undo="$emit('undo')"
            @redo="$emit('redo')"
            :selector="selector"
    >
      <template v-slot:actions="params">
        <div :style="{backgroundColor: params.input}"
             class="h-6 w-6 border-2 rounded-3xl border-gray-300 flex justify-center items-center text-white"
             @click="togglePalette" >
          <font-awesome-icon
                  v-show="isShowingPalette"
                  icon="times"></font-awesome-icon>
        </div>
      </template>
      <template v-slot:inputting>
        <div
                v-click-outside="onBlurPalette"
                class="flex justify-center">
          <chrome v-model="color" @input="onColorChanged" />

        </div>
      </template>
    </picker>
  </div>

</template>

<script>
import 'vue-slider-component/theme/antd.css'
import Chrome from 'vue-color/src/components/Chrome.vue';
import vClickOutside from 'v-click-outside'
import Picker from "./Picker";
import tinycolor from 'tinycolor2';

export default {
  name: 'ColorPicker',
  components: {Chrome, Picker},
  props: {
      value: {},
    label: String,
    selector: String,
  },

  data(){
    let color = this.value? tinycolor(this.value) : tinycolor('#ffffff');

    return {
      color: color,
      isShowingPalette: false,
      hasOpenedPalette: false,
      parsedColor: this.value || '#ffffff'
    }
  },

  watch: {

    value(v){
      v = v || '#ffffff';
      if(v){//this.color = v;
        this.color = tinycolor(v).toHex8String();
        this.parsedColor = tinycolor(v).toHex8String();
      }
    }
  },
  methods: {

    togglePalette(){
      this.isShowingPalette = !this.isShowingPalette;
      this.hasOpenedPalette = false;
    },

    onColorChanged(v){
      console.dir(v)
      this.parsedColor = v.hex8;
      this.$emit('input', this.parsedColor)
    },
    onBlurPalette(){
      if(!this.hasOpenedPalette){
        this.hasOpenedPalette = true;
        return;
      }

      if(this.isShowingPalette){
        this.isShowingPalette = false
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.label{
  margin-top: 4px;


}
</style>
