import cssParamParser from "../cssParamParser";
import InteractionType from "../InteractionType";

export default class Resolver{

    _values = {};
    name = "";

    resolve(selector, groupId, itemId, value) {
        this._setItem(selector, groupId, itemId, value);
        return this._doResolve(selector, groupId, itemId, value);
    }

    _doResolve(selector, groupId, itemId, value){}

    _setItem(selector, groupId, itemId, value){
        interactionRecorder.record(selector, groupId, this._itemIdToInteractionName(itemId), InteractionType.STYLE, value, value);
    }

    parseItem(selector, groupId, itemId, position, style){
        let value = cssParamParser.parseArgument(position, style);
        //this._setItem(selector, groupId, itemId, value);
        return value;
    }

    _getItemValue(selector, groupId, itemId, defaultValue){
        //let value = selectorItems[itemId] || defaultValue;
        let value = interactionRecorder.getPropertyValue(selector, groupId, this._itemIdToInteractionName(itemId)) || defaultValue;
        return value + ' ';
    }

    getSelectedValue(selector, groupId, itemId){
        return interactionRecorder.getPropertyValue(selector, groupId, this._itemIdToInteractionName(itemId))
    }

    undo(selector, groupId, itemId){
        interactionRecorder.undoPropertyValue(selector, groupId, this._itemIdToInteractionName(itemId));
        let value = this.getSelectedValue(selector, groupId, itemId);
        return this._doResolve(selector, groupId, itemId, value);
    }

    redo(selector, groupId, itemId){
        interactionRecorder.redoPropertyValue(selector, groupId, this._itemIdToInteractionName(itemId))
        let value = this.getSelectedValue(selector, groupId, itemId);
        return this._doResolve(selector, groupId, itemId, value);
    }

    _getItemValueWithoutSpace(selector, groupId, itemId, defaultValue){
        return interactionRecorder.getPropertyValue(selector, groupId, this._itemIdToInteractionName(itemId)) || defaultValue;
    }

    _itemIdToInteractionName(itemId){
        return this.name + itemId;
    }


}