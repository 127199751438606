<template>
  <div class="border-gray-300 border rounded bg-white gap-3 grid p-3 mb-3">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'SetterLayout',
  components: {
  },

  data(){

    return {};
  },

  mounted(){
  }
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
