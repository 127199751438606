<template>

  <picker
          :label="label"
          :isInputting="true"
          :input="input"
          :unused="unused"
          @reset="$emit('reset')"
          @undo="$emit('undo')"
          @redo="$emit('redo')"
  >
    <template v-slot:subbar v-if="options">


      <dropdown-list-select
              :fetch="fetch"
              @select="onTypeSelected"
              placeholder="Assign task to..."
              :is-select-on-active="true"
      >

        <template v-slot:button>
          <div
                  class="flex items-center pt-1"
          >
            {{ selectedType }}
          </div>
        </template>

        <template v-slot:item="props">
          <div
                  @click="props.select"
                  class="hover:bg-gray-200 flex items-center p-2"
                  :class="{'bg-gray-300': props.active}">

            <div class="pl-2">
              {{ props.item }}
            </div>
          </div>
        </template>

      </dropdown-list-select>
    </template>

    <template v-slot:input="params">
      <slot name="input" :input="params.input">
          {{ params.input }}
      </slot>
    </template>

    <template v-slot:inputting>
      <div :class="{'opacity-10': !isSelectingLength}">
        <vue-slider
                :min="min"
                :max="max"
                :interval="interval"
                v-model="number"></vue-slider>
        <div
                v-if="hasUnits"
                class="flex justify-between">
          <div v-for="(u) in units"
                  @click="onUnitChanged(u)"
                  :class="{'opacity-10': !isUnit(u)}">{{ u }}</div>
        </div>

      </div>


    </template>
  </picker>

</template>


<script>
  import 'vue-slider-component/theme/antd.css'
  import Chrome from 'vue-color/src/components/Chrome.vue';
  import vClickOutside from 'v-click-outside'
  import Picker from "./Picker";
  import DropdownListSelect from "sharerui/src/components/ui/DropdownListSelect";
  import cssParamParser from "../../services/cssParamParser";

  export default {
    name: 'SliderUnitsPicker',
    components: {Picker, Chrome, DropdownListSelect},
    props: {
      name:{},
      unused: {
        type: Boolean,
        default: false
      },
        min:{
            type: Number,
            default: 1
        },
        interval: {
          type: Number,
          default: 1
        },
        max: {
          type: Number,
          default: 10
        },
      value: {},
      label: String,
      type: {
          type: String,
        default: 'Length'
      },
      hasUnits: {
        type: Boolean,
        default: true
      },
      options: Array,
      units: {
          default: () => ['px', 'em', 'rem', '%'],
          type: Array
      }
    },


    data(){

      let data = {
        input: this.value,
        selectedType: this.type || 'Length',
        unit: this.units[0],
        number: this.min === 0? 0 : 1
      };

      let quantity = cssParamParser.parseSingleArgumentWithUnit(this.value);

      if(quantity){
        data.number = quantity.amount;
        data.unit = quantity.unit;
      }


      return data;
    },

    computed: {
      isSelectingLength(){
        return this.selectedType === 'Length';
      }
    },

    watch: {
      input(v){
        this.$emit('input', this.input)
      },

        value(v, v2){
          let quantity = cssParamParser.parseSingleArgumentWithUnit(v);

          if(quantity){
            this.number = quantity.amount;
            this.unit = quantity.unit;
          }
        /*if(this.name === 'bs'){

          alert(v)
        }
          this.input = v;

          let s = "" + v;
          let number = window.parseFloat(s);

          if(!window.isNaN(number)){
            this.number = number;
          }else{
            this.number = 1
          }

          let withoutNumber = s.replace(number, '');
          if(this.units.indexOf(withoutNumber) !== -1){
            this.unit = withoutNumber;
          }*/

         },

      number(){
        this.onNumberChanged();
      }
    },

    methods: {


      onTypeSelected(type){
        this.selectedType = type;
        this.emit();
      },

      emit(){

        if(this.isSelectingLength){
          this.$emit('input', '' + this.number + (this.hasUnits? this.unit: ''));
        }else{
          this.$emit('input', this.selectedType)
        }

      },

      isUnit(unit){
        return this.unit === unit;
      },

      onUnitChanged(u){
        this.unit = u;
        this.onNumberChanged(this.number)
      },

      onNumberChanged(){
        this.emit()
      },

      fetch(term){
        return ['Length', ...this.options].filter(item => item.indexOf(term) !== -1)
      }

    },
    directives: {
      clickOutside: vClickOutside.directive
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }


</style>
