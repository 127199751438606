import css from "@/services/css"

class ElementDeselectedMessageHandler{

    handle(interaction){

        interactionRecorder.deselect(interaction.selector, interaction.taskUuid);


        store.state.taskStats[interaction.taskUuid].elements = interactionRecorder.getNumberOfGroupSelectors(interaction.taskUuid)
        store.state.taskStats[interaction.taskUuid].changes = interactionRecorder.getNumberOfGroupPropertyValues(interaction.taskUuid)

        css.refreshAllCss();
    }
}

let elementDeselectedMessageHandler = new ElementDeselectedMessageHandler();
export default elementDeselectedMessageHandler;
