
import css from "@/services/css"
import modificationDao from "../daos/ModificationDao";

import busEvents from "../bus/busEvents";
import bus from "../bus/bus";
import selectorGenerator from "../services/selectorGenerator";
import StyleSetEvent from "../events/StyleSetEvent";
import Interaction from "../models/Interaction";
import actionQueue from "sharerui/src/service/ActionQueue"
import SetPropertyAction from "../services/action/SetPropertyAction";
import SetProperty from "../action/contract/command/SetProperty";
import propertiesResolver from "../services/propertyresolver/PropertiesResolver";
import store from "../store/Store";
import interactionRefresher from "../router/InteractionRefresher";



export default {
    name: 'StyleSetter',
    components: {
    },
    props: {
        groupId: {},
        el: {}
    },

    data(){
        return {
            propertyName: '',
            propertyType: null,
            unWatchSelectedValue: null,
            selectedValue: null,
            type: 'style',
            resolver: null,
            itemId: null,
            itemPosition: null,

            isChangingElement: true,
            doSave: _.throttle((el, property, v) => {
                actionQueue.add(new SetPropertyAction(new SetProperty(
                (new Interaction(
                    window._collabRocket.viewUuid,
                    store.state.focusedTask.uuid,
                    this.type,
                    selectorGenerator.get(this.el),
                    property,
                    v
                )))));
            }, 1000),
        }
    },

    computed: {
      selector(){
          return selectorGenerator.get(this.el) || '';
      }

    },

    methods: {
        save(el, v){

            if(this.selectedValue === null
                || this.selectedValue === ''
                || this.selectedValue === undefined){
                return;
            }

            if(this.resolver){
                let props = this.resolver.resolve(selectorGenerator.get(this.el), this.groupId, this.itemId, v);
                this._reflectCssValues(props)

            }else{
                if(this.type === "style"){
                    css.setGroup(this.el, this.groupId, this.propertyName, v);
                }

                this.doSave(this.el, this.propertyName, v);
            }

            this.addActiveInteractionGroupId();
        },

        _reflectCssValues(cssValues){
            for (let cssValue of cssValues){
                //css.setStyle(this.el, cssValue.name, cssValue.value);
                css.setGroup(this.el, this.groupId, cssValue.name, cssValue.value);
                this.doSave(this.el, cssValue.name, cssValue.value);
            }
        },

        onReset(){
        },

        onRedo(){
            store.addActiveInteractionGroupId(this.groupId);

            if(this.resolver){
                this._reflectCssValues(this.resolver.redo(selectorGenerator.get(this.el), this.groupId, this.itemId));
            }else{
                css.redoPropertyValue(this.el, this.groupId, this.propertyName)
                this._wrapInPropertyWatch(() => this.selectedValue = css.getRecordedPropertyValue(this.selector, this.groupId, this.propertyName))

            }
            //this.propertyValue = css.getRecordedPropertyValue(this.selector, this.groupId, this.propertyName)

            this.addActiveInteractionGroupId();
        },

        onUndo(){
            if(this.resolver){
                this._reflectCssValues(this.resolver.undo(selectorGenerator.get(this.el), this.groupId, this.itemId));

            }else{
                css.undoPropertyValue(this.el, this.groupId, this.propertyName);
                this._wrapInPropertyWatch(() => this.selectedValue = css.getRecordedPropertyValue(this.selector, this.groupId, this.propertyName));
            }

            this.addActiveInteractionGroupId();
            //this.propertyValue = css.getRecordedPropertyValue(this.selector, this.groupId, this.propertyName)

        },

        addActiveInteractionGroupId(){
            if(!store.isGroupActive(this.groupId)){
                store.addActiveInteractionGroupId(this.groupId);
                interactionRefresher.refresh();
            }
        },

        mapSelectedValueToStyle(v){
            return v;
        },

        mapStyleToSelectedValue(v){
            return v;
        },

        parseStyle(v){
            this.selectedValue = this.resolver.parseItem(this.selector, this.groupId, this.itemId, this.itemPosition === null? this.itemId: this.itemPosition, v);
        },

        _wrapInPropertyWatch(fn){
            if(this.unWatchSelectedValue){
                this.unWatchSelectedValue();
            }

            fn();

            this.watchSelectedValue()
        },

        watchSelectedValue(v){
            this.unWatchSelectedValue = this.$watch('selectedValue', (v) => {
                this.save(this.el, this.mapSelectedValueToStyle(v))
            })
        },

        /**
         *
         * @param {StyleSetEvent} e
         */
        onStyleSet(e){
            if(e && e.selector === this.selector
                && e.propertyName === this.propertyName
                && e.propertyValue !== this.mapSelectedValueToStyle(this.selectedValue)){
                 this.selectedValue = e.propertyValue;
            }
        }
    },

    watch: {
        selector(v){

            let that = this;
            this._wrapInPropertyWatch(function(){

                if(that.resolver && that.resolver.propertyName){
                    let value = css.getRecordedPropertyValue(v, that.groupId, that.resolver.propertyName);
                    if(value){
                        that.parseStyle(value);
                    }
                }else if(!that.resolver){
                    let value = css.getRecordedPropertyValue(v, that.groupId, that.propertyName);
                    that.selectedValue = that.mapStyleToSelectedValue(value)
                }
            });
        },

        el(v){
           // this._wrapInPropertyWatch(() => this.propertyValue = this.mapStyleToPropertyValue(css.get(v, this.propertyName)))
        }
    },

    mounted(){
        this.watchSelectedValue();

        bus.$on(StyleSetEvent.name, function(e){
            this.onStyleSet(e);
        }.bind(this));


    },
}

