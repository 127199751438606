import GetMissionControl from "../action/contract/query/GetMissionControl";
import TaskStats from "../models/TaskStats";
import getMissionControlHandler from "../action/handler/query/GetMissionControlHandler";
import Vue from 'vue'
import css from "./css";
import InteractionType from "./InteractionType";
import store from "../store/Store";



class MissionControlDataLoader {
    async loadData() {
        if(store.state.tasks.length){
            return;
        }

        const a = await getMissionControlHandler.handle(new GetMissionControl(window._collabRocket.viewUuid));
        // const a = viewBoardMapper.map(viewBoardData);
        window.a = this.a;

        this._mapInteractions(a.interactions);
        this._mapTasks(a.tasks);
        this._mapTaskComments(a.taskComments);
        store.addTasks(a.tasks);
        store.state.currentUser = a.user;
    }

    /**
     *
     * @param interactions {Array.<InteractionDescriptor>}
     * @private
     */
    _mapInteractions(interactions){
        for (let interaction of interactions) {
            if (interaction.type === InteractionType.STYLE || interaction.type === InteractionType.OPTION) {
                interactionRecorder.record(interaction.selector,
                    interaction.taskUuid,
                    interaction.property,
                    interaction.type,
                    this._mapOldPropertyValue(interaction),
                    this._mapNewPropertyValue(interaction));

                console.log(interaction)
            } else{
                    interactionRecorder.select(interaction.selector, interaction.taskUuid);
            }
        }
    }

    _mapOldPropertyValue(interaction){

        if(interaction.type === InteractionType.OPTION && interaction.property === "editableContent"){
            return document.querySelector(interaction.selector).innerText;
        }else if(interaction.type === InteractionType.OPTION && interaction.property === "isEditable"){
            return interaction.value === "1";
        }else{
            return css.get(document.querySelector(interaction.selector), interaction.property);
        }
    }

    _mapNewPropertyValue(interaction){
        if(interaction.type === InteractionType.OPTION && interaction.property === "isEditable"){
            return interaction.value === "1";
        }else{
            return interaction.value;
        }
    }

    _mapTasks(tasks){
        for (let task of tasks) {
            Vue.set(store.state.taskStats, task.uuid, new TaskStats());
            Vue.set(store.state.comments, task.uuid, []);
            store.state.taskStats[task.uuid].changes = interactionRecorder.getNumberOfGroupPropertyValues(task.uuid);
            store.state.taskStats[task.uuid].elements = interactionRecorder.getNumberOfGroupSelectors(task.uuid);
        }
    }

    _mapTaskComments(taskComments){
        for (let taskId of Object.keys(taskComments)) {
            Vue.set(store.state.comments, taskId, taskComments[taskId]);
        }
    }
}

let missionControlDataLoader = new MissionControlDataLoader();
export default missionControlDataLoader;

