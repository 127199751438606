<template>

  <div>
    <div class="p-3 flex items-center cursor-pointer" >
      <div class="image-preview-container mr-3 flex-shrink-0	overflow-hidden text-5xl	bg-yellow-200	text-white p-1" :style="{opacity: opacity}">
        <img class="h-full object-cover	object-left-top hidden	" src="" ref="image">

        < />
      </div>
      <div>
        <div>
          {{ nodeName }}
        </div>
        <div class="text-em-xs">
          {{ selector }}
        </div>
      </div>
    </div>

    <div class="ml-3 mb-3">
      {{ numberOfPropertiesLabel }}
    </div>

  </div>

</template>

<script>

import MarginSetter from "@/components/styles/MarginSetter";
import css from "@/services/css"
import nodeNameFormatter from "../../services/NodeNameFormatter";
import store from "../../store/Store";
import bus from "../../bus/bus";
import StyleSetEvent from "../../events/StyleSetEvent";
export default {
  name: 'SelectorPreview',
  props: {
    selector: String
  },
  components: {
  },

  data(){

    return {
      opacity: 1,
      nodeName: null,
      numberOfPropertiesLabel: '',
      state: store.state,
      throttledOnSelectorUpdated: _.throttle((v) => {
        this.onSelectorUpdated(v, true)
      }, 2000, {trailing: true}),

      updatePreviewImageThrottled: _.throttle((v) => {
       // this.updatePreviewImage(v)
      }, 4000, {trailing:true}),
    };
  },

  mounted(){
    this.onSelectorUpdated(this.selector)

    bus.$on(StyleSetEvent.name, function(e){
      this.throttledOnSelectorUpdated(this.selector);
    }.bind(this));

  },

  methods: {

    updatePreviewImage(v){
      if(!this.$refs.image){
        return
      }

      let that = this;
      //this.focusedElementPropertyValues = css.getSelectorGroupPropertyValues(v, store.state.focusedTask.id)

      this.opacity = 0.2;
      window.html2canvas = html2canvas;
      let element = document.querySelector(v);
      this.nodeName = nodeNameFormatter.format(element.nodeName);

      setTimeout(function () {
        html2canvas(document.querySelector(v), {
          scrollX: -window.scrollX,
          scrollY: -window.scrollY,
          scale: 1,
          useCORS: false
        }).then(canvas => {
          let oldCanvas = canvas.toDataURL("image/png")
          if(canvas.height > 80){
            canvas.height = 80
          }

          let img = new Image();
          img.src = oldCanvas;
          img.onload = function (){
            canvas.getContext("2d").drawImage(img, 0, 0);
            that.$refs.image.src = canvas.toDataURL();
            that.opacity = 1;
          };
        });
      }, 500)

    },

    onSelectorUpdated(v, useThrottledUpdatePreviewImage){
      useThrottledUpdatePreviewImage = useThrottledUpdatePreviewImage || false;
      if(!v ||!store.state.focusedTask){
        return;
      }


      if(useThrottledUpdatePreviewImage){
        this.updatePreviewImageThrottled(v);
      }else{
        //this.updatePreviewImage(v)
      }

      this.updateNumberOfProperties(v)
    },

    updateNumberOfProperties(v){
      let num = interactionRecorder.getNumberOfGroupPropertyValues(store.state.focusedTask.uuid)
      console.log(store.state.focusedTask.uuid)

      if(num  === 0){
        this.numberOfPropertiesLabel = 'No changes';
      }else if(num  === 1){
        this.numberOfPropertiesLabel = '1 change'
      }else{
        this.numberOfPropertiesLabel = num  + ' changes'
      }
    }
  },


  watch: {
    selector(v){
      this.onSelectorUpdated(v);
    }
  },

}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
