export default class GroupOptionSet  {

    static name = 'GroupOptionSet';

    selector;
    groupId;
    optionName;
    optionValue;


    constructor(selector, groupId, optionName, optionValue) {
        this.selector = selector;
        this.groupId = groupId;
        this.optionName = optionName;
        this.optionValue = optionValue;
    }
}