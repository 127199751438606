class NodeNameFormatter{


    format(name){
        return this._mapping[name.toLowerCase()] || name;
    }


    _mapping = {
        img: 'Image',
        p: 'Paragraph',
        div: 'Division',
        span: 'Span',
        table: 'table',
        td: 'Table Data',
        th: 'Table Header',
        canvas: 'Canvas',
        button: 'Button'
    };

}

let nodeNameFormatter = new NodeNameFormatter();
export default nodeNameFormatter;