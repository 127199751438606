<template>
    <div>
        Padding All
        <setter-layout>
            <slider-units-picker
                    @reset="onReset"
                    @undo="onUndo"
                    @redo="onRedo"
                    :max="500"
                    :min="0"
                    :unused="unused"
                    v-model="selectedValue"></slider-units-picker>
        </setter-layout>

    </div>
</template>

<script>
import css from "@/services/css"

import 'vue-slider-component/theme/antd.css'
import StyleSetter from "../../../mixin/StyleSetter";
import SliderUnitsPicker from "../SliderUnitsPicker";
import SetterLayout from "../SetterLayout";
import bus from "../../../bus/bus";
import StyleSetEvent from "../../../events/StyleSetEvent";
import paddingAllResolver from "../../../services/propertyresolver/paddingAllResolver";

export default {
    name: 'PaddingAllSetter',
    components: {SetterLayout, SliderUnitsPicker},
    mixins: [StyleSetter],
    data(){
        return {
            resolver: paddingAllResolver,
            itemId: 'all',
            unused: false

        }
    },

    methods: {

        setUnused(){
            this.unused =
                !((css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-top') ===
                css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-right'))
                    &&
                    (css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-right') ===
                        css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-bottom')) &&
                        (css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-bottom') ===
                css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-left')));

            if(!this.unused){
                this._wrapInPropertyWatch(() => this.selectedValue = css.getRecordedPropertyValue(this.selector, this.groupId, 'padding-top'));
            }

        }
    },

    watch: {
        selector(){
            this.setUnused()
        },

    },

    async mounted() {
        bus.$on(StyleSetEvent.name, function(e){
            this.setUnused();
        }.bind(this));

    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
