import css from "@/services/css"
import store from "../../store/Store";

class TaskUpdatedMessageHandler{

    /**
     *
     * @param task {Task}
     */
    handle(task){
        store.addTask(task)
    }
}

let taskUpdatedMessageHandler = new TaskUpdatedMessageHandler();
export default taskUpdatedMessageHandler;
