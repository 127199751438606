<template>



  <picker
          :label="label"
          :isInputting="true"
          :input="input"
          @reset="$emit('reset')"
          @undo="$emit('undo')"
          @redo="$emit('redo')"

  >

    <template v-slot:input="params">
      <slot name="input" :input="params.input">
          {{ params.input }}
      </slot>
    </template>

    <template v-slot:inputting>
        <vue-slider
                :min="min"
                :max="max"
                v-model="input"></vue-slider>
    </template>



  </picker>

</template>


<script>
  import 'vue-slider-component/theme/antd.css'
  import Chrome from 'vue-color/src/components/Chrome.vue';
  import vClickOutside from 'v-click-outside'
  import Picker from "./Picker";


  export default {
    name: 'SliderPicker',
    components: {Picker, Chrome},
    props: {
        min:{
            type: Number,
            default: 1
        },
        max: {
          type: Number,
          default: 10
        },
      value: {},
      label: String
    },


    data(){
      return {
        input: this.value,
      }
    },

    watch: {
      input(v){
        this.$emit('input', this.input)
      },

        value(v){
          this.input = v;
         }
    },
    methods: {

    },
    directives: {
      clickOutside: vClickOutside.directive
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }


</style>
