import interactionRecorder from "./InteractionRecorder";
import selectorGenerator from "./selectorGenerator";
import bus from "../bus/bus";
import busEvents from "../bus/busEvents";
import StyleSetEvent from "../events/StyleSetEvent";
import InteractionType from "./InteractionType";

class Css{

    _cssRecorder = interactionRecorder;

    setMany(el, styles){
        Object.assign(el.style, styles);
    }

    set(el, propertyName, propertyValue){
        this._doSet(el, propertyName, propertyValue)
    }

    undoPropertyValue(el, groupId, propertyName){
        let selector = selectorGenerator.get(el);
        this._cssRecorder.undoPropertyValue(selector, groupId, propertyName)

        let value = interactionRecorder.getPropertyValue(selector, groupId, propertyName);
        //this._emitSet(selector, propertyName, value);

        this._doSet(el, propertyName, interactionRecorder.getPropertyValue(selector, groupId, propertyName))
    }

    redoPropertyValue(el, groupId, propertyName){
        let selector = selectorGenerator.get(el);
        this._cssRecorder.redoPropertyValue(selector, groupId, propertyName)

        let value = interactionRecorder.getPropertyValue(selector, groupId, propertyName);
        //this._emitSet(selector, propertyName, value);

        this._doSet(el, propertyName, interactionRecorder.getPropertyValue(selector, groupId, propertyName))
    }

    setGroup(el, groupId, propertyName, propertyValue){

        if(this.get(el, propertyName) === propertyValue){
            return;
        }

        let selector = selectorGenerator.get(el);

        let currentValue = this.get(el, propertyName);
        interactionRecorder.record(selectorGenerator.get(el), groupId, propertyName, InteractionType.STYLE, currentValue, propertyValue);
        this.setStyle(el, propertyName, propertyValue)

    }

    setStyle(el, propertyName, propertyValue){
        this._doSet(el, propertyName, propertyValue);
        this._emitSet(selectorGenerator.get(el), propertyName, propertyValue);
    }


    _emitSet(selector, propertyName, propertyValue){
       bus.$emit(StyleSetEvent.name, new StyleSetEvent(selector, propertyName, propertyValue));
    }

    _doSet(el, propertyName, propertyValue){

        el.style.setProperty(propertyName, propertyValue, "important")
    }

    get(el, propertyName){
        return el.style[propertyName]? el.style[propertyName]: window.getComputedStyle(el).getPropertyValue(
            propertyName.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
        );
    }

    getRecordedPropertyValue(selector, groupId, propertyName){
        return this._cssRecorder.getPropertyValue(selector, groupId, propertyName)
    }

    getSelectorGroupPropertyValues(selector, groupId){
        return this._cssRecorder.getSelectorGroupPropertyValues(selector, groupId, InteractionType.STYLE)
    }

    getSelectorsUsedByGroup(groupId){
        return this._cssRecorder.getSelectorsUsedByGroup(groupId);
    }

    getAsInt(el, style){
        return parseInt(this.get(el, style))
    }


    refreshAllCss(priorityFunction, activePredicate){
        let that = this;
        interactionRecorder.visitProperties(priorityFunction, activePredicate, InteractionType.STYLE,
            function(selector, propertyName, propertyValue){
                let el = document.querySelector(selector);
                that._doSet(el, propertyName, propertyValue)
            });
    }


}

const css = new Css();
export default css;