<template>
    <div>
        Font Size

        <slider-units-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :max="50"
                :type="'Length'"
                :options="['initial']"
                v-model="selectedValue"></slider-units-picker>

    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import StyleSetter from "../../../mixin/StyleSetter";
import TextPicker from "../TextPicker";
import SliderPicker from "../SliderPicker";
import DropdownListSelect from "sharerui/src/components/ui/DropdownListSelect";
import SliderUnitsPicker from "../SliderUnitsPicker";

export default {
    name: 'FontSizeSetter',
    components: {SliderUnitsPicker, SliderPicker, TextPicker, DropdownListSelect},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'font-size',
        }
    },

    methods: {

      /*  mapSelectedValueToStyle(v){
            return  v;
        },

        mapStyleToSelectedValue(v){
            return v;
            // return window.parseInt(v);
        },*/

    },

    async mounted() {
    },


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
