<template>
    <div>
        Column Count

        <slider-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :max="5"
                v-model="selectedValue"></slider-picker>

    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import axios from 'axios'
import modificationDao from "../../daos/ModificationDao";
import _ from 'lodash'
import StyleSetter from "../../mixin/StyleSetter";
import Chrome from 'vue-color/src/components/Chrome.vue';
import tinycolor from 'tinycolor2';
import ColorPicker from "./ColorPicker";
import TextPicker from "./TextPicker";
import ImagePicker from "./ImagePicker";
import ListPicker from "./ListPicker";
import loadedFontFinder from "../../services/LoadedFontFinder";
import SliderPicker from "./SliderPicker";

export default {
    name: 'ColumnCountSetter',
    components: {SliderPicker, TextPicker},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: '',
          propertyName: 'column-count',
            options: []
        }
    },

    methods: {

    },

    async mounted() {
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
