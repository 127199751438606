import axios from 'axios'

class SetPropertyHandler {

    /**
     *
     * @param command {SetProperty}
     * @returns {Promise<void>}
     */
    async handle(command) {
        var model = await axios.put(window._collabRocket.apiUrl + '/api/project/view/property',
            {
                'view_uuid': command.interaction.viewUuid,
                'task_uuid': command.interaction.taskUuid,
                'type': command.interaction.type,
                'property': command.interaction.property,
                'selector': command.interaction.selector,
                'value': command.interaction.value,
            },
            {
                withCredentials: true
            }
        )
    }


}

let setPropertyHandler = new SetPropertyHandler();
export default setPropertyHandler;