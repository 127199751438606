<template>

    <div>

        <task-card
                :isFocused="store.isTaskFocusedByIndex(this.taskIndex)"
                :task="store.getTaskByIndex(taskIndex)"
                :comments="store.getCommentsByTaskIndex(taskIndex)"
                @click="onFocusTask"
                @mouseenter="onMouseEnter"
                :v-show="!store.getTaskByIndex(taskIndex).isArchived"
                :current-user="store.state.currentUser"
                :tasks="store.state.tasks"
                @toggleArchive="onToggleArchived(store.getTaskByIndex(taskIndex))"
                @defocus="onDefocusTask"
                @taskSaved="onTaskSaved"
                @taskDeleted="onDefocusTask"
                @onUnsavedTaskBlurred="store.defocusTask()"
        >
            <template v-slot:header>
                <task-change-tracker :task-index="taskIndex"></task-change-tracker>
            </template>

            <template v-slot:controls>
                <div class="pr-4 pl-2 hover:text-yellow-300 cursor-pointer" @click="$emit('onInspect')">
                    <font-awesome-icon icon="paint-brush" />
                </div>
            </template>
        </task-card>
    </div>

</template>

<script>


import store from "../store/Store";
import bus from "../bus/bus";
import FocusedTaskEnteredEvent from "../events/TaskEnteredEvent";
import actionQueue from "sharerui/src/service/ActionQueue"
import searchUsers from "sharerui/src/action/handler/query/SearchUsersHandler";
import SearchUsers from "sharerui/src/action/contracts/query/SearchUsers";
import vClickOutside from 'v-click-outside'
import ChangeTaskAction from "sharerui/src/service/ChangeTaskAction";
import DropdownListSelect from "sharerui/src/components/ui/DropdownListSelect";
import ListSelect from "sharerui/src/components/ui/ListSelect";
import TaskCard from "sharerui/src/components/ui/TaskCard";
import ChangeTask from "sharerui/src/action/contracts/command/ChangeTask";
import searchSwimlanesHandler from "../action/contract/query/SearchSwimlanesHandler";
import SearchSwimlanes from "../action/contract/command/SearchSwimlanes";
import TaskChangeTracker from "./TaskChangeTracker";
import {TaskComment} from "sharerui/src/models/TaskComment";
import CreateTaskCommentAction from "sharerui/src/service/action/CreateTaskCommentAction";
import CreateTaskComment from "sharerui/src/action/contracts/command/CreateTaskComment";
import CreateTaskAction from "sharerui/src/service/CreateTaskAction";
import CreateTask from "sharerui/src/action/contracts/command/CreateTask";
import SelectElementAction from "../services/action/SelectElementAction";
import SelectElement from "../action/contract/command/SelectElement";
import ProfileImage from "./ui/ProfileImage";

export default {
  name: 'TaskForm',
    props: {
        active: Boolean,
        taskIndex:  Number
    },

  components: {
      TaskCard,
      ProfileImage,
      TaskChangeTracker,
      DropdownListSelect,
      ListSelect
  },

  data(){
      return {
          store: store,
          content: '',
          description: ''
      }
  },

    methods: {

      onTaskSaved(){
          let selectors = interactionRecorder.getSelectorsUsedByGroup(store.getTaskByIndex(this.taskIndex).uuid);

          for(let selector of selectors){
              actionQueue.add(new SelectElementAction(new SelectElement(
                  selector,
                  window._collabRocket.viewUuid,
                  store.getTaskByIndex(this.taskIndex).uuid
              )));
          }
      },

        onDefocusTask(){
          console.log("defocus")
          store.defocusTask();

          //store.state.focusedTask = null;
        },

      onFocusTask(e){
          /*if(store.state.focusedTask === store.state.swimlanes[0].tasks[this.taskIndex]){

              //store.state.focusedTask = null;
          }else{
              store.state.focusedTask = store.state.swimlanes[0].tasks[this.taskIndex];
          }*/

          store.focusTask(store.state.tasks[this.taskIndex])
          //store.state.focusedTask = store.state.tasks[this.taskIndex];

      },

        onToggleArchived(task){
            task.isArchived = !task.isArchived;
            actionQueue.add(new ChangeTaskAction(new ChangeTask(task, null )));
        },

        onMouseEnter(){
          if(store.isTaskFocusedByIndex(this.taskIndex)){
              bus.$emit(FocusedTaskEnteredEvent.name)
          }
        }
    },

    watch: {

        'store.state.focusedTask': function(v){
            if(store.getTaskByIndex(this.taskIndex) === v){
                setTimeout(function () {
                    //this.$refs.content.focus();
                }.bind(this), 100)
                console.dir(document.activeElement)
            }
        },

    },

    computed:{


    },
  mounted(){
  },
    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>


<style scoped lang="scss">


    .mission-controls{
        border-bottom: 1px solid;
    }

    .task-form{
        margin: 15px;
        box-sizing: border-box;

        display: flex;
        justify-content: center;
        flex-direction: column;

    }

    .focused{
        border: 6px solid goldenrod;
    }

    .outer-rounded{
        border-radius: 10px;
        background: white;
    }

    .inner-rounded-focused{
        border-radius: 6px;
    }

    .inner-rounded{
        border-radius: 8px;
    }





    .defocused:after {
        content  : "";
        position : absolute;
        z-index  : 1;
        bottom   : 0;
        left     : 0;
        pointer-events   : none;
        background-image : linear-gradient(179deg,
                rgba(255,255,255, 0),
                rgba(255,255,255, 1) 90%);
        border-radius: 8px;
        height   : 2em;
        width: 100%
    ;
    }
</style>
