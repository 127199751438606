import setPropertyHandler from "../../action/handler/command/SetPropertyHandler";
import selectElementHandler from "../../action/handler/command/SelectElementHandler";
import deselectElementHandler from "../../action/handler/command/DeselectElementHandler";

export default class DeselectElementAction{

    /**
     * @type {DeselectElement}
     */
    _deselectElement;

    constructor(deselectElement) {
        this._deselectElement = deselectElement;
    }

    async run() {
        await deselectElementHandler.handle(this._deselectElement)
    }
}
