<template>

  <div class="switch-wrapper">
    <label class="switch font-normal	">
      <input v-model='inputValue' type="checkbox" id="werwer" @input="$emit('input', $event.target.checked)">
      <div class="slider round"  >
        <span class="before">

        </span>
        <span class="after">
           <span class="off">
            {{ this.offLabel }}
          </span>
          <span class="on">
            {{ this.onLabel }}
          </span>

        </span>

      </div>
    </label>
  </div>


</template>

<script>


export default {
  name: 'Slider',
  components: {
  },

  props: {
    onLabel: {default: 'On'},
    offLabel: {default: 'Off'},
    value: {
      default: true,
      type: Boolean
    }
  },

  data(){

    return {
      inputValue: this.value,
    };
  },

  watch: {
    value(v){
      this.inputValue = v;
    }
  },

  mounted(){
  }
}
</script>


<style lang="scss" scoped>

  .switch-wrapper{
    .margin-left: 15px;
    .margin-right: 15px;
    .margin-top: 10px;
    .margin-bottom: -10px;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
  }

  .switch input {display:none;}

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(156,163,175);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider .before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: rgba(245,158,11);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px rgba(245,158,11);
  }

  input:checked + .slider .before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(55px);
  }


  /*------ ADDED CSS ---------*/
  .slider .after
  {
    color: white;
    display: block;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    font-size: 10px;
    font-family: Verdana, sans-serif;
  }

  input + .slider .off
  {
    display: block;
  }

  input + .slider .on
  {
    display: none;
  }


  input:checked + .slider .on
  {
    display: block;
  }

  input:checked + .slider .off
  {
    display: none;
  }

  input:checked + .slider .after{
  }


  .on{
    white-space: nowrap;
    position: relative;
    left: -14px;
  }


  .off{
    white-space: nowrap;
    position: relative;
    left: 14px;
  }


</style>
