export default class ElementFocusedEvent {

    static name = 'ElementFocusedEvent';
    element;
    oldElement;
    isManualSelection;


    constructor(element, oldElement, isManualSelection) {
        this.element = element;
        this.oldElement = oldElement;
        this.isManualSelection = isManualSelection;
    }
}