<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Border</h2>
    </div>
    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3">
      <border-color-setter :el="el" :group-id="groupId"></border-color-setter>
    </div>

      <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
        <border-style-setter :el="el" :group-id="groupId"></border-style-setter>
      </div>

      <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
       <border-width-setter :el="el" :group-id="groupId"></border-width-setter>
      </div>
      <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
        <border-radius-setter :el="el" :group-id="groupId"></border-radius-setter>
      </div>
    </div>
  </div>
</template>

<script>

import vClickOutside from 'v-click-outside'
import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
import BorderColorSetter from "../styles/border/BorderColorSetter";
import BorderStyleSetter from "../styles/border/BorderStyleSetter";
import BorderWidthSetter from "../styles/border/BorderWidthSetter";
import BorderRadiusSetter from "../styles/border/BorderRadiusSetter";

export default {
  name: 'BorderCategory',
  components: {
    BorderRadiusSetter,
    BorderWidthSetter,
    BorderStyleSetter,
    BorderColorSetter,
    BackgroundImageSetter,
    BackgroundColorSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },

  mounted(){
  }
}
</script>


<style lang="scss">



/**
<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>
    <router-view/>
 */
</style>
