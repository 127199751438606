<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Typography</h2>
    </div>
    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3">
      <width-setter :el="el" :group-id="groupId"></width-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mt-3">
      <height-setter :el="el" :group-id="groupId"></height-setter>
    </div>
  </div>
</template>

<script>
 import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import WidthSetter from "../styles/dimensions/WidthSetter";
 import HeightSetter from "../styles/dimensions/HeightSetter";

export default {
  name: 'DimensionCategory',
  components: {
    HeightSetter,
    WidthSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
