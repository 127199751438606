import Echo from "laravel-echo";
import axios from "axios";


import taskCommentMapper from "sharerui/src/action/mappers/TaskCommentMapper"
import taskMapper from "sharerui/src/action/mappers/TaskMapper"
import interactionUpdateMessageHandler from "../messages/InteractionUpdateMessageHandler";
import interactionDescriptorMapper from "../../action/contract/mapper/InteractionDescriptorMapper";
import elementDeselectedMessageHandler from "../messages/ElementDeselectedMessageHandler";
import taskCommentUpdatedMessageHandler from "../messages/TaskCommentUpdatedMessageHandler";
import taskUpdatedMessageHandler from "../messages/TaskUpdatedMessageHandler";
import messageConnector from "sharerui/src/service/listeners/MessageConnector";
import taskCommentDeletedMessageHandler from "../messages/TaskCommentDeletedMessageHandler";

window.axios = axios;

class MessageListener{

    _echo;

    startListening(){
        messageConnector.connect();
        messageConnector.echo.private('sites.' +  window._collabRocket.siteUuid)
            .listen('.InteractionUpdated', function (data) {
                interactionUpdateMessageHandler.handle(interactionDescriptorMapper.map(data.interaction));
            })
            .listen('.ElementDeselected', function (data) {
                elementDeselectedMessageHandler.handle(interactionDescriptorMapper.map(data));
            }).listen('.ElementDeselected', function (data) {
            elementDeselectedMessageHandler.handle(interactionDescriptorMapper.map(data));
        }).listen('.TaskCommentUpdated', function (data) {
            taskCommentUpdatedMessageHandler.handle(taskCommentMapper.map(data.taskComment));
        }).listen('.TaskCommentDeleted', function (data) {
            taskCommentDeletedMessageHandler.handle(data.uuid);
        }).listen('.TaskUpdated', function (data) {
            taskUpdatedMessageHandler.handle(taskMapper.map(data.task));
        });
    }

    _setUp(){
        //let isTls = true; //window.location.hostname.split(".").length > 1;
        let isTls = window.location.hostname.split(".").length > 1;

        window.echo = this._echo = new Echo({
            broadcaster: 'pusher',
            key: 'websocketkey',
            //wsHost: 'api.collabrocket.io', // window._collabRocket.socketsHost,
            wsHost: window._collabRocket.socketsHost,
            wsPort: 6001,
            wssPort: 6001,
            forceTLS: isTls,
            enabledTransports: ['ws', 'wss'],

            disableStats: true,
            authEndpoint : window._collabRocket.apiUrl + '/broadcasting/auth',
            withCredentials: true,
            encrypted: isTls,

            /*auth: {
                withCredentials: true,
                headers: {
                    'Authorization': 'Bearer BADKEHVxNGKnFON0HiN4j7AyQi8es1hk4MeocdxVGpdHQnsUm7vOyMvvV6Vs',
                    'Accept': 'application/json'
                }
            }*/
        });

        let that = this;

        Pusher.Runtime.createXHR = function () {
            var xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            return xhr
        };

        if(that._echo.socketId()){
            alert(14)
            that._setSocketIdHeader();
        }else{
            that._echo.connector.pusher.connection.bind('connected', () => {
                alert(45)
                that._setSocketIdHeader();
            });
        }
        //Vue.prototype.$echo = echo;

    }

    _setSocketIdHeader(){
        alert('seting')
        let that = this;
        console.log(that._echo.socketId());
        axios.interceptors.request.use(function(config){
            alert(49999999999999999999999999)
            alert(that._echo.socketId())
            config.headers.common.lol = 99999;
            console.log(that._echo.socketId());
            if (that._echo.socketId()) {
                console.log(config.headers.common);
                config.headers.common['X-Socket-ID'] = that._echo.socketId();
            }
            console.log(config.headers.common)
            return config
        });

    }




}

let messageListener = new MessageListener();
export default messageListener;