<template>
  <div data-type="inspector">
    <div
            class="flex px-1 m-4 text-gray-600"
         >
      <div @click="goBack" class="cursor-pointer">

        <font-awesome-icon icon="arrow-left" />
      </div>

      <div @click="closeInspectMode" class="ml-3 italic text-gray-600 cursor-pointer task-description">
        <quill-mention-editor
                :read-only="true"
                :clickable="true"
                :contents="state.focusedTask && state.focusedTask.description"
                :text-only="true"
                :no-padding="true"
        ></quill-mention-editor>
      </div>
    </div>


    <div v-show="pageMode !== 'elements'">
      <shaker ref="focusedElementDescriptor">
        <div class=" m-3 order-blue-600 border rounded bg-blue-500	text-white"  @click="onHighlightFocusedElement">
          <SelectorPreview
                  v-show="state.focusedElement"
                  :selector="selector"></SelectorPreview>
          <div class="p-3" v-show="!state.focusedElement">
            Please select an element
          </div>
        </div>
      </shaker>
    </div>

    <div v-show="pageMode === 'elements'">
      <div  @click="onSelectorUsedByGroupFocused(groupSelector)"
            v-for="groupSelector in selectorsUsedByGroup" >
        <SelectorPreview
                @click="onSelectorUsedByGroupFocused(groupSelector)"
                :selector="groupSelector"></SelectorPreview>
      </div>

    </div>

    <div v-show="pageMode === 'categories'">

      <div class="pl-3 flex justify-evenly	text-em-sm" v-show="numberOfProperties > 0">
        <div @click="categoriesMode = 'all'" class="hover:text-gray-500 cursor-pointer">All Styles</div>
        <div>❙</div>
        <div @click="categoriesMode = 'changes'" class="hover:text-gray-500 cursor-pointer"> Changes </div>
      </div>

      <div v-show="categoriesMode === 'all' || (category.isChanged) || numberOfProperties === 0" v-for="category in categories"
           @click="onCategoryFocused(category)"
           class="flex justify-start bg-white  border-gray-300 border rounded text-gray-600 p-3 m-3 cursor-pointer">
        <div class="mr-2 mt-px text-em-sm text-gray-400 hidden" >
          <font-awesome-icon :icon="['far', 'star']"  />
        </div>

        <div>
          <div>
            {{ category.label }}
          </div>
          <div class="text-em-sm text-gray-400 ">

            {{ category.description || '' }} &zwnj;

          </div>
        </div>
      </div>
    </div>

    <div v-show="pageMode === 'category'"
      class="p-4 pt-0">

      <background-category
              :el.sync="state.focusedElement"
              v-show="categories.background.active"
      ></background-category>

      <typography-category
              :el.sync="state.focusedElement"
              v-show="categories.typography.active"
      ></typography-category>

      <border-category
              :el.sync="state.focusedElement"
              v-show="categories.border.active"
      ></border-category>

      <spacing-category
              :el.sync="state.focusedElement"
              v-show="categories.spacing.active" >
      </spacing-category>

      <dimension-category
              :el.sync="state.focusedElement"
              v-show="categories.dimension.active"
      ></dimension-category>

      <display-category
              :el.sync="state.focusedElement"
              v-show="categories.display.active"
      ></display-category>

      <position-category
              :el.sync="state.focusedElement"
              v-show="categories.position.active"
      ></position-category>

      <shadow-category
              :el.sync="state.focusedElement"
              v-show="categories.shadow.active"
      ></shadow-category>

      <filter-category
              :el.sync="state.focusedElement"
              v-show="categories.filter.active"
      ></filter-category>

      <transform-category
              :el.sync="state.focusedElement"
              v-show="categories.transform.active"
      ></transform-category>

      <image-category
              :el.sync="state.focusedElement"
              v-show="categories.image.active"
      ></image-category>


      <!--<margin-setter :el.sync="state.focusedElement" :groupId.sync="state.focusedTask.id"></margin-setter>
      <color-setter  :el.sync="state.focusedElement" :groupId.sync="state.focusedTask.id"></color-setter>
      <background-color-setter :el.sync="state.focusedElement" :groupId.sync="state.focusedTask.id"></background-color-setter>-->
    </div>

  </div>
</template>

<script>

import MarginSetter from "@/components/styles/MarginSetter";
import css from "@/services/css"
import Selector from "./styles/Selector";
import store, {ViewMode} from "../store/Store";
import ColorSetter from "./styles/ColorSetter";
import BackgroundColorSetter from "./styles/BackgroundColorSetter";
import BackgroundCategory from "./categories/BackgroundCategory";
import selectorGenerator from "../services/selectorGenerator";
import bus from "../bus/bus";
import Shaker from "./ui/Shaker";
import html2canvas from "html2canvas";
import SelectorPreview from "./ui/SelectorPreview";
import FocusedElementHighlightedEvent from "../events/FocusedElementHighlightedEvent";
import TypographyCategory from "./categories/TypographyCategory";
import BorderCategory from "./categories/BorderCategory";
import DimensionCategory from "./categories/DimensionCategory";
import DisplaySetter from "./styles/display/DisplaySetter";
import DisplayCategory from "./categories/DisplayCategory";
import PositionCategory from "./categories/PositionCategory";
import ShadowCategory from "./categories/ShadowCategory";
import FilterCategory from "./categories/FilterCategory";
import TransformCategory from "./categories/TransformCategory";
import ImageCategory from "./categories/ImageCategory";
import SpacingCategory from "./categories/SpacingCategory";
import StyleCategoryName from "../models/StyleCategoryName";
import QuillMentionEditor from "sharerui/src/components/ui/QuillMentionEditor";


//pseudo selector

export default {
  name: 'Inspector',
  components: {
    SpacingCategory,
    ImageCategory,
    TransformCategory,
    FilterCategory,
    ShadowCategory,
    PositionCategory,
    DisplayCategory,
    DisplaySetter,
    DimensionCategory,
    BorderCategory,
    TypographyCategory,
    SelectorPreview,
    Shaker,
    BackgroundCategory,
    BackgroundColorSetter,
    ColorSetter,
    MarginSetter,
    QuillMentionEditor
  },

  data(){

    return{
      state: store.state,
      pageMode: PageMode.CATEGORIES,
      categoriesMode: CategoriesMode.ALL,

      numberOfProperties: 0,
      categories: {
        background: {
          label: 'Background',
          description: 'Color, image, blur',
          active: false,
          styleCategoryName: StyleCategoryName.BACKGROUND,
          isChanged: false
        },

        typography: {
          label: 'Typography',
          active: false,
          description: 'font, columns, transform, spacing', //white space, word break, letter spacing, wiriting model
          styleCategoryName: StyleCategoryName.TYPOGRAPHY,
          isChanged: false
        },

        border: {
          label: 'Border',
          active: false,
          description: 'Color, style, width, radius',
          styleCategoryName: StyleCategoryName.BORDER,
          isChanged: false
        },

        spacing: {
          label: 'Spacing',
          active: false,
          description: 'Margin, padding',
          styleCategoryName: StyleCategoryName.SPACING,
          isChanged: false

        },

        display: {
          label: 'Display',
          active: false,
          description: 'display, box sizing', //order
          styleCategoryName: StyleCategoryName.DISPLAY,
          isChanged: false
        },

        position: {
          label: 'Position',
          active: false,
          description: 'Static, relative, sticky...',
          styleCategoryName: StyleCategoryName.POSITION,
          isChanged: false
        },

        shadow: {
          label: 'Shadow',
          active: false,
          description: 'Box, text',
          styleCategoryName: StyleCategoryName.SHADOW,
          isChanged: false
        },

        dimension: {
          label: 'Dimensions',
          active: false,
          description: 'Width, Height',
          styleCategoryName: StyleCategoryName.DIMENSION,
          isChanged: false

        },


        filter: {
          label: 'Filters',
          active: false,
          description: 'Opacity, sepia, hue rotate...',
          styleCategoryName: StyleCategoryName.FILTER,
          isChanged: false

        },


       transform: {
          label: 'Transforms',
          active: false,
          description: 'Skew, rotate',
         styleCategoryName: StyleCategoryName.TRANSFORM,
         isChanged: false
        },

        image: {
          label: 'Image',
          active: false,
          description: 'Rendering, object fit',
          styleCategoryName: StyleCategoryName.IMAGE,
          isChanged: false
        }

        /*content: {
          label: 'Content',
          active: false,
          description: 'Shape outside'

        },

        table: {
          label: 'Table',
          active: false,
          description: 'Layout'
        }*/


      },
      focusedCategory: null,
      focusedElementPropertyValues: {},
      selectorsUsedByGroup: []
    }

  },

  methods: {
    onHighlightFocusedElement(){
      bus.$emit(FocusedElementHighlightedEvent.name, new FocusedElementHighlightedEvent());
    },

    onSelectorUsedByGroupFocused(selector){
      store.setFocusedElement(document.querySelector(selector));
      this.pageMode = PageMode.CATEGORIES;
    },

    updateSelectorRecordData(){
      if(!this.state.focusedTask){
        return;
      }

      this.numberOfProperties = Object.keys(css.getSelectorGroupPropertyValues(this.selector, this.state.focusedTask.id)).length;
      this.selectorsUsedByGroup = css.getSelectorsUsedByGroup(store.state.focusedTask.uuid);
    },

    closeInspectMode(){
      store.closeInspectMode()
    },

    goBack(){
      this.updateSelectorRecordData();


      if(this.pageMode === PageMode.CATEGORY && this.selectorsUsedByGroup.length){
        this.pageMode = PageMode.CATEGORIES;
      }
      else if(this.pageMode === PageMode.CATEGORIES && this.selectorsUsedByGroup.length){
        this.pageMode = PageMode.ELEMENTS;
        store.setFocusedElement(null);
      }else{
        store.closeInspectMode();
      }
    },

    onCategoryFocused(category){
      if(!store.state.focusedElement){
        this.$refs.focusedElementDescriptor.shake()
        return
      }

      if(this.focusedCategory){
        this.focusedCategory.active = false;
      }

      this.pageMode = PageMode.CATEGORY;
      category.active = true;
      this.focusedCategory = category;
    },

    updateIsChanged(){
      if(!this.selector){
        return;
      }

      for(const [name, category] of Object.entries(this.categories)){
        let isChanged = false;
        console.dir(StyleCategoryName.properties[category.styleCategoryName])
        for(const property of StyleCategoryName.properties[category.styleCategoryName]){
          isChanged = isChanged || interactionRecorder.hasInteractionForGroupByPropertyName(this.selector, store.state.focusedTask.uuid, property);
        }

        category.isChanged = isChanged;
      }
    }
  },

  computed: {
    selector(){
      return selectorGenerator.get(this.state.focusedElement);
    }
  },

  watch: {
    selector(newS){
      this.updateIsChanged()
    },

    categoriesMode(){
      this.updateIsChanged()
    },

    'state.focusedElement': function(v){
      if(!v){
        return;
      }

      let that = this;
      this.updateSelectorRecordData();
      this.pageMode = PageMode.CATEGORIES

    },

    'state.viewMode': function(v){
      if(v === ViewMode.inspect){
        this.selectorsUsedByGroup = css.getSelectorsUsedByGroup(store.state.focusedTask.uuid)
        if(!this.selectorsUsedByGroup.length){
          this.pageMode = PageMode.CATEGORIES
        }else{
          this.pageMode = PageMode.ELEMENTS
        }
      }
    }
  },

  mounted(){
    window.html2canvas = html2canvas;

  }

}

class PageMode{
  static CATEGORIES = 'categories';
  static ELEMENTS = 'elements';
  static CATEGORY = 'category';
}

class CategoriesMode{
  static CHANGES = 'changes';
  static ALL = 'all';
}
</script>


<style lang="scss" scoped>

  .image-preview-container{
    width: 100px;
    height: 50px;
  }

  .task-description{
    margin-top: 3px;
  }

</style>
