export default class GetMissionControl{

    _viewId;


    constructor(viewId) {
        this._viewId = viewId;
    }


    get viewId() {
        return this._viewId;
    }
}
