<template>
  <div>
    <div class="mb-4">
      <h2 class="text-xl">Tex Shadow</h2>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <text-shadow-horizontal-setter :el="el" :group-id="groupId"></text-shadow-horizontal-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <text-shadow-vertical-setter :el="el" :group-id="groupId"></text-shadow-vertical-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <text-shadow-blur-radius :el="el" :group-id="groupId"></text-shadow-blur-radius>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 ">
      <text-shadow-color-setter :el="el" :group-id="groupId"></text-shadow-color-setter>
    </div>

    <div class="mb-4">
      <h2 class="text-xl">Tex Shadow</h2>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <box-shadow-horizontal-setter :el="el" :group-id="groupId"></box-shadow-horizontal-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <box-shadow-vertical-setter :el="el" :group-id="groupId"></box-shadow-vertical-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <box-shadow-blur-radius :el="el" :group-id="groupId"></box-shadow-blur-radius>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 mb-3">
      <box-shadow-spread-setter :el="el" :group-id="groupId"></box-shadow-spread-setter>
    </div>

    <div class="border-gray-300 border rounded bg-white	gap-3 grid p-3 ">
      <box-shadow-color-setter :el="el" :group-id="groupId"></box-shadow-color-setter>
    </div>

  </div>
</template>

<script>
 import BackgroundColorSetter from "../styles/BackgroundColorSetter";
import BackgroundImageSetter from "../styles/BackgroundImageSetter";
import store from "../../store/Store";
import Category from "../../mixin/Category";
 import FontFamilySetter from "../styles/FontFamilySetter";
 import ColumnCountSetter from "../styles/ColumnCountSetter";
 import TextTransformSetter from "../styles/TextTransformSetter";
 import LetterSpacingSetter from "../styles/LetterSpacingSetter";
 import WidthSetter from "../styles/dimensions/WidthSetter";
 import HeightSetter from "../styles/dimensions/HeightSetter";
 import TextShadowHorizontalSetter from "../styles/shadow/TextShadowHorizontalSetter";
 import TextShadowVerticalSetter from "../styles/shadow/TextShadowVerticalSetter";
 import TextShadowBlurRadius from "../styles/shadow/TextShadowBlurRadiusSetter";
 import TextShadowColorSetter from "../styles/shadow/TextShadowColorSetter";
 import BoxShadowHorizontalSetter from "../styles/shadow/BoxShadowHorizontalSetter";
 import BoxShadowSpreadSetter from "../styles/shadow/BoxShadowSpreadSetter";
 import BoxShadowBlurRadius from "../styles/shadow/BoxShadowBlurRadiusSetter";
 import BoxShadowColorSetter from "../styles/shadow/BoxShadowColorSetter";
 import BoxShadowVerticalSetter from "../styles/shadow/BoxShadowVerticalSetter";

export default {
  name: 'ShadowCategory',
  components: {
    BoxShadowSpreadSetter,
    BoxShadowColorSetter,
    BoxShadowBlurRadius,
    BoxShadowVerticalSetter,
    BoxShadowHorizontalSetter,
    TextShadowColorSetter,
    TextShadowBlurRadius,
    TextShadowVerticalSetter,
    TextShadowHorizontalSetter
  },

  mixins: [Category],

  props: {
    el: {}
  },

  data(){
    return {
      state: store.state
    }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
</style>
