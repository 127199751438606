export default class SelectElement{

    _selector;
    _viewUuid;
    _taskUuid;

    constructor(selector, viewUuid, taskUuid) {
        this._selector = selector;
        this._viewUuid = viewUuid;
        this._taskUuid = taskUuid;
    }

    get selector() {
        return this._selector;
    }

    get viewUuid() {
        return this._viewUuid;
    }

    get taskUuid() {
        return this._taskUuid;
    }
}