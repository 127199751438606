export default class StyleCategoryName {

    static BACKGROUND = 'background';
    static TYPOGRAPHY = 'typography';
    static BORDER = 'border';
    static SPACING = 'spacing';
    static DISPLAY = 'display';
    static POSITION = 'position';
    static SHADOW = 'shadow';
    static DIMENSION = 'dimension';
    static FILTER = 'filter';
    static TRANSFORM = 'transform';
    static IMAGE = 'image';

    static properties = {};

    static constructor(){
        this.properties[this.BACKGROUND] = [
            'background-image',
            'background-color'
        ];

        this.properties[this.TYPOGRAPHY] = [
            'isEditable',
            'letter-spacing',
            'text-transform',
            'column-count',
            'font-family',
            'background-image',
            'background-color',
            'font-size'
        ];

        this.properties[this.BORDER] = [
            'border-radius',
            'border-width',
            'border-style',
            'border-color'
        ];


        this.properties[this.SPACING] = [
            'padding-top',
            'padding-right',
            'padding-bottom',
            'padding-left',
            'margin-top',
            'margin-right',
            'margin-bottom',
            'margin-left'
        ];


        this.properties[this.DISPLAY] = [
            'display',
            'box-sizing'
        ];

        this.properties[this.POSITION] = [
            'display',
            'box-sizing'
        ];

        this.properties[this.SHADOW] = [
            'text-shadow',
            'box-shadow'
        ];

        this.properties[this.DIMENSION] = [
            'width',
            'height'
        ];

        this.properties[this.FILTER] = [
            'opacity',
            'filter'
        ];

        this.properties[this.TRANSFORM] = [
            'transform'
        ];

        this.properties[this.IMAGE] = [
            'image-rendering',
            'object-fit'
        ];
    }

}

StyleCategoryName.constructor();