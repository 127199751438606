export default class SetProperty{

    /**
     *
     * @param {Interaction} _interaction
     */
    _interaction;

    constructor(modification) {
        this._interaction = modification;
    }

    get interaction() {
        return this._interaction;
    }
}