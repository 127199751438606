<template>
    <div>
        <color-picker
                @reset="onReset"
                @undo="onUndo"
                @redo="onRedo"
                :selector="selector"
                label="Border Color"
                :property-name="propertyName"
                v-model="selectedValue" />
    </div>

</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import css from "@/services/css"
import Echo from 'laravel-echo'
import axios from 'axios'
import _ from 'lodash'
import StyleSetter from "../../../mixin/StyleSetter";
import Chrome from 'vue-color/src/components/Chrome.vue';
import tinycolor from 'tinycolor2';
import ColorPicker from "../ColorPicker";

export default {
    name: 'BorderColorSetter',
    components: {ColorPicker, Chrome},
    mixins: [StyleSetter],
    data(){
        return {
          selectedValue: 'inherit',
          propertyName: 'border-color'
        }
    },

  methods: {
    mapSelectedValueToStyle(v){
      return  v; //v.hex8
    },

    mapStyleToSelectedValue(v){
      //console.dir(44444444444444444444444);
      //console.dir(v);
      //console.dir(tinycolor(v).toHex8String());
      return v && tinycolor(v).toHex8() !== '00000000'?  tinycolor(v).toHex8String(): '#ffffff'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
