import interactionRecorder from "./InteractionRecorder";
import bus from "../bus/bus";
import EditableInputted from "../events/EditableInputted";
import actionQueue from "sharerui/src/service/ActionQueue";
import SetPropertyAction from "./action/SetPropertyAction";
import SetProperty from "../action/contract/command/SetProperty";
import Interaction from "../models/Interaction";
import selectorGenerator from "./selectorGenerator";
import GroupActivated from "../events/GroupActivated";
import GroupDeactivated from "../events/GroupDeactivated";
import InteractionsRefreshed from "../events/InteractionsRefreshed";
import InteractionType from "./InteractionType";
import ElementFocusedEvent from "../events/ElementFocusedEvent";
import store from "../store/Store";

class EditableObserver {

    _groupHandlersCreated = {};

    static _isInited = (function(){


        bus.$on(ElementFocusedEvent.name, function (e) {

            console.log(e.oldElement)
            if(e.oldElement){
                e.oldElement.contentEditable = false;
            }
        });


            bus.$on(InteractionsRefreshed.name, function (e) {

                interactionRecorder.visitProperties((t) => t.uuid,
                    (id) => !!store.state.activeInteractionGroupIds[id],
                    InteractionType.OPTION,
                    function(selector, propertyName, propertyValue){
                        console.log(selector + " " + propertyName + " " + propertyValue)
                        if(propertyName === "editableContent"){
                                document.querySelector(selector).innerText = propertyValue;
                        }
                    });
            });

        interactionRecorder.onRecord(function (interactionRecorded) {
            if(interactionRecorded.propertyName === "isEditable"){
                editableObserver._addEventHandler(interactionRecorded.selector, interactionRecorded.groupId, interactionRecorded.newPropertyValue )
            }


        })

    })();

    setGroupOption(selector, groupId, isEditable){

        let el = document.querySelector(selector);
        if(store.state.focusedElement === el){
            el.contentEditable = isEditable;
        }

        actionQueue.add(new SetPropertyAction(new SetProperty(
            (new Interaction(
                window._collabRocket.viewUuid,
                groupId,
                'option',
                selector,
                'isEditable',
                isEditable
            )))));

        this._addEventHandler(selector, groupId, isEditable);


        interactionRecorder.record(selector, groupId, 'isEditable', Interaction.OPTION, isEditable, isEditable);

    }

    _addEventHandler(selector, groupId, isEditable){
        let element = document.querySelector(selector);
        //element.contentEditable = isEditable;


        let key = groupId + "_" + selector;
        if(!this._groupHandlersCreated[key]){
            this._groupHandlersCreated[key] = true;
            element.addEventListener('input', function(){
                bus.$emit(EditableInputted.name, new EditableInputted(selector, groupId, e.target.innerText))
            })
        }
    }
}

let editableObserver = new EditableObserver();
export default editableObserver;